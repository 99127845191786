export const A3208 =
    [
        {
            id: 1,
            code: "A-3208-11",
            name: "Сумка-органайзер L",
            link: "https://lihitlab-jp.ru/images/A3208/A-3208_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/225411538/detail.aspx",
        },

        {
            id: 1,
            code: "A-3208-16",
            name: "Сумка-органайзер L",
            link: "https://lihitlab-jp.ru/images/A3208/A-3208_16.jpg",
            color: "Серый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/225411539/detail.aspx",
        },

        {
            id: 1,
            code: "A-3208-24",
            name: "Сумка-органайзер L",
            link: "https://lihitlab-jp.ru/images/A3208/A-3208_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/225411540/detail.aspx",
        },
        
        
       
    ]

export const A3208POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3208/A-3208_poi1.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3208/A-3208_poi2.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3208/A-3208_poi3.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3208/A-3208_poi4.jpg",
        },

        
      

    ]