export const A3221 =
    [
        {
            id: 1,
            code: "A-3221-9",
            name: "Столешница для контейнера 20л",
            link: "https://lihitlab-jp.ru/images/A3221/A-3221.jpg",
            color: "Коричневый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/225419795/detail.aspx",
        },
        
        
       
    ]

export const A3221POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3221/A-3221_poi1.jpg",
        },
             
      

    ]