export const A2223 =
    [
        {
            id: 1,
            code: "A-2223-7",
            name: "Пенал широкий",
            link: "https://lihitlab-jp.ru/images/A2223/A-2223_7.jpg",
            color: "Фисташковый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222273688/detail.aspx",
        },
        {
            id: 2,
            code: "A-2223-11",
            name: "Пенал широкий",
            link: "https://lihitlab-jp.ru/images/A2223/A-2223_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222273689/detail.aspx",
        },
        {
            id: 3,
            code: "A-2223-16",
            name: "Пенал широкий",
            link: "https://lihitlab-jp.ru/images/A2223/A-2223_16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222273690/detail.aspx",
        },
        {
            id: 4,
            code: "A-2223-24",
            name: "Пенал широкий",
            link: "https://lihitlab-jp.ru/images/A2223/A-2223_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222273691/detail.aspx",
        },    
        
       
    ]

export const A2223POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A2223/A-2223_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A2223/A-2223_poi7.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A2223/A-2223_poi8.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A2223/A-2223_poi9.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A2223/A-2223_poi10.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A2223/A-2223_poi11.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A2223/A-2223_poi12.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A2223/A-2223_poi13.jpg",
        },
      
      
      

    ]