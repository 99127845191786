export const A3222 =
    [
        {
            id: 1,
            code: "A-3222-0",
            name: "Контейнер складной, разборный 32л",
            link: "https://lihitlab-jp.ru/images/A3222/A-3222_0.jpg",
            color: "Белый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/225417781/detail.aspx",
        },

        {
            id: 1,
            code: "A-3222-24",
            name: "Контейнер складной, разборный 32л",
            link: "https://lihitlab-jp.ru/images/A3222/A-3222_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/225417782/detail.aspx",
        },
        
        
       
    ]

export const A3222POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3222/A-3222_poi1.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3222/A-3222_poi2.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3222/A-3222_poi4.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3222/A-3222_poi5.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3222/A-3222_poi6.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3222/A-3222_poi7.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3222/A-3222_poi8.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3222/A-3222_poi9.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3222/A-3222_poi10.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3222/A-3222_poi11.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3222/A-3222_poi12.jpg",
        },

        
      

    ]