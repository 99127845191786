export const A2224 =
    [
        {
            id: 1,
            code: "A-2224-7",
            name: "Пенал-косметичка",
            link: "https://lihitlab-jp.ru/images/A2224/A-2224_7.jpg",
            color: "Фисташковый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222401819/detail.aspx",
        },
        {
            id: 2,
            code: "A-2224-11",
            name: "Пенал-косметичка",
            link: "https://lihitlab-jp.ru/images/A2224/A-2224_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222401820/detail.aspx",
        },
        {
            id: 3,
            code: "A-2224-16",
            name: "Пенал-косметичка",
            link: "https://lihitlab-jp.ru/images/A2224/A-2224_16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222401821/detail.aspx",
        },
        {
            id: 4,
            code: "A-2224-24",
            name: "Пенал-косметичка",
            link: "https://lihitlab-jp.ru/images/A2224/A-2224_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222401822/detail.aspx",
        },    
        
       
    ]

export const A2224POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A2224/A-2224_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A2224/A-2224_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A2224/A-2224_poi3.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A2224/A-2224_poi9.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A2224/A-2224_poi10.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A2224/A-2224_poi11.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A2224/A-2224_poi12.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A2224/A-2224_poi13.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A2224/A-2224_poi14.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A2224/A-2224_poi15.jpg",
        },
      
      
      

    ]