import './App.css';

import React from 'react';
import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Header from '../Header/Header';
import Navigation from '../Navigation/Navigation';

import SimpleSlider from '../Slider/Slider';

import Catalog from '../Catalog/Catalog';
import CatalogImg from '../CatalogImg/CatalogImg';


import Main from '../Main/Main';



import Brend from '../Brend/Brend';

import Reviews from '../Reviews/Reviews';

import Gallery from '../Gallery/Gallery';

import Contacts from '../Contacts/Contacts';


import Footer from '../Footer/Footer';




import { CATALOG } from '../../utils/catalog/catalog';

import { CARDS } from '../../utils/cards/cards';

import { PUNILABO } from '../../utils/catalog/punilabo/punilabo';
import { A7712 } from '../../utils/catalog/punilabo/a7712';
import { A7712POI } from '../../utils/catalog/punilabo/a7712';

import { A7713 } from '../../utils/catalog/punilabo/a7713';
import { A7713POI } from '../../utils/catalog/punilabo/a7713';

import { A7714 } from '../../utils/catalog/punilabo/a7714';
import { A7714POI } from '../../utils/catalog/punilabo/a7714';

import { A7716 } from '../../utils/catalog/punilabo/a7716';
import { A7716POI } from '../../utils/catalog/punilabo/a7716';

import { A7717 } from '../../utils/catalog/punilabo/a7717';
import { A7717POI } from '../../utils/catalog/punilabo/a7717';

import { A7718 } from '../../utils/catalog/punilabo/a7718';
import { A7718POI } from '../../utils/catalog/punilabo/a7718';

import { A7720 } from '../../utils/catalog/punilabo/a7720';
import { A7720POI } from '../../utils/catalog/punilabo/a7720';

import { A7721 } from '../../utils/catalog/punilabo/a7721';
import { A7721POI } from '../../utils/catalog/punilabo/a7721';

import { A7722 } from '../../utils/catalog/punilabo/a7722';
import { A7722POI } from '../../utils/catalog/punilabo/a7722';

import { A7723 } from '../../utils/catalog/punilabo/a7723';
import { A7723POI } from '../../utils/catalog/punilabo/a7723';

import { A7724 } from '../../utils/catalog/punilabo/a7724';
import { A7724POI } from '../../utils/catalog/punilabo/a7724';

import { A7725 } from '../../utils/catalog/punilabo/a7725';
import { A7725POI } from '../../utils/catalog/punilabo/a7725';

import { A7727 } from '../../utils/catalog/punilabo/a7727';
import { A7727POI } from '../../utils/catalog/punilabo/a7727';

import { A7728 } from '../../utils/catalog/punilabo/a7728';
import { A7728POI } from '../../utils/catalog/punilabo/a7728';

import { A7780 } from '../../utils/catalog/punilabo/a7780';
import { A7780POI } from '../../utils/catalog/punilabo/a7780';

import { A7781 } from '../../utils/catalog/punilabo/a7781';
import { A7781POI } from '../../utils/catalog/punilabo/a7781';

import { A7782 } from '../../utils/catalog/punilabo/a7782';
import { A7782POI } from '../../utils/catalog/punilabo/a7782';

import { A7783 } from '../../utils/catalog/punilabo/a7783';
import { A7783POI } from '../../utils/catalog/punilabo/a7783';

import { A7784 } from '../../utils/catalog/punilabo/a7784';
import { A7784POI } from '../../utils/catalog/punilabo/a7784';

import { A7785 } from '../../utils/catalog/punilabo/a7785';
import { A7785POI } from '../../utils/catalog/punilabo/a7785';

import { A7800 } from '../../utils/catalog/punilabo/a7800';
import { A7800POI } from '../../utils/catalog/punilabo/a7800';

import { A7803 } from '../../utils/catalog/punilabo/a7803';
import { A7803POI } from '../../utils/catalog/punilabo/a7803';

import { A7804 } from '../../utils/catalog/punilabo/a7804';
import { A7804POI } from '../../utils/catalog/punilabo/a7804';








import { ALTNA } from '../../utils/catalog/altna/altna';
import { A7760 } from '../../utils/catalog/altna/a7760';
import { A7760POI } from '../../utils/catalog/altna/a7760';

import { A7750 } from '../../utils/catalog/altna/a7750';
import { A7750POI } from '../../utils/catalog/altna/a7750';

import { A7751 } from '../../utils/catalog/altna/a7751';
import { A7751POI } from '../../utils/catalog/altna/a7751';

import { A7752 } from '../../utils/catalog/altna/a7752';
import { A7752POI } from '../../utils/catalog/altna/a7752';

import { A7773 } from '../../utils/catalog/altna/a7773';
import { A7773POI } from '../../utils/catalog/altna/a7773';

import { A7791 } from '../../utils/catalog/altna/a7791';
import { A7791POI } from '../../utils/catalog/altna/a7791';

import { A7767 } from '../../utils/catalog/altna/a7767';
import { A7767POI } from '../../utils/catalog/altna/a7767';

import { A7768 } from '../../utils/catalog/altna/a7768';
import { A7768POI } from '../../utils/catalog/altna/a7768';

import { A7790 } from '../../utils/catalog/altna/a7790';
import { A7790POI } from '../../utils/catalog/altna/a7790';

import { A7764 } from '../../utils/catalog/altna/a7764';
import { A7764POI } from '../../utils/catalog/altna/a7764';

import { A7765 } from '../../utils/catalog/altna/a7765';
import { A7765POI } from '../../utils/catalog/altna/a7765';

import { A7759 } from '../../utils/catalog/altna/a7759';
import { A7759POI } from '../../utils/catalog/altna/a7759';









import { SMARTFIT } from '../../utils/catalog/smaartfit/smartfit';

import { A8800 } from '../../utils/catalog/smaartfit/a8800';
import { A8800POI } from '../../utils/catalog/smaartfit/a8800';

import { A7701 } from '../../utils/catalog/smaartfit/a7701';
import { A7701POI } from '../../utils/catalog/smaartfit/a7701';

import { A7702 } from '../../utils/catalog/smaartfit/a7702';
import { A7702POI } from '../../utils/catalog/smaartfit/a7702';

import { A7574 } from '../../utils/catalog/smaartfit/a7574';
import { A7574POI } from '../../utils/catalog/smaartfit/a7574';

import { A7575 } from '../../utils/catalog/smaartfit/a7575';
import { A7575POI } from '../../utils/catalog/smaartfit/a7575';

import { A7576 } from '../../utils/catalog/smaartfit/a7576';
import { A7576POI } from '../../utils/catalog/smaartfit/a7576';

import { A7577, A7577POI } from '../../utils/catalog/smaartfit/a7577';


import { N1627, N1627POI } from '../../utils/catalog/smaartfit/n1627';

import { N1647, N1647POI } from '../../utils/catalog/smaartfit/n1647';

import { A7585, A7585POI } from '../../utils/catalog/smaartfit/a7585';

import { A7581, A7581POI } from '../../utils/catalog/smaartfit/a7581';

import { A7582, A7582POI } from '../../utils/catalog/smaartfit/a7582';

import { A7583, A7583POI } from '../../utils/catalog/smaartfit/a7583';

import { A7595, A7595POI } from '../../utils/catalog/smaartfit/a7595';

import { A7650, A7650POI } from '../../utils/catalog/smaartfit/a7650';

import { A7651, A7651POI } from '../../utils/catalog/smaartfit/a7651';

import { A7554, A7554POI } from '../../utils/catalog/smaartfit/a7554';

import { A7558, A7558POI } from '../../utils/catalog/smaartfit/a7558';

import { A7559, A7559POI } from '../../utils/catalog/smaartfit/a7559';

import { A7551, A7551POI } from '../../utils/catalog/smaartfit/a7551';

import { A7552, A7552POI } from '../../utils/catalog/smaartfit/a7552';

import { A7555, A7555POI } from '../../utils/catalog/smaartfit/a7555';

import { A7556, A7556POI } from '../../utils/catalog/smaartfit/a7556';

import { A7557, A7557POI } from '../../utils/catalog/smaartfit/a7557';

import { A7652, A7652POI } from '../../utils/catalog/smaartfit/a7652';

import { A7653, A7653POI } from '../../utils/catalog/smaartfit/a7653';









import { ACTACT } from '../../utils/catalog/actact/actact';
import { A7694, A7694POI } from '../../utils/catalog/actact/a7694';
import { A7695, A7695POI } from '../../utils/catalog/actact/a7695';
import { A7670, A7670POI } from '../../utils/catalog/actact/a7670';
import { A7672, A7672POI } from '../../utils/catalog/actact/a7672';
import { A7680, A7680POI } from '../../utils/catalog/actact/a7680';
import { A7681, A7681POI } from '../../utils/catalog/actact/a7681';
import { A7682, A7682POI } from '../../utils/catalog/actact/a7682';
import { A7683, A7683POI } from '../../utils/catalog/actact/a7683';
import { A7686, A7686POI } from '../../utils/catalog/actact/a7686';
import { A7687, A7687POI } from '../../utils/catalog/actact/a7687';
import { A7688, A7688POI } from '../../utils/catalog/actact/a7688';




import { HINEMO } from '../../utils/catalog/hinemo/hinemo';
import { A7900, A7900POI } from '../../utils/catalog/hinemo/a7900';
import { A7901, A7901POI } from '../../utils/catalog/hinemo/a7901';
import { A7902, A7902POI } from '../../utils/catalog/hinemo/a7902';
import { A7910, A7910POI } from '../../utils/catalog/hinemo/a7910';
import { A7911, A7911POI } from '../../utils/catalog/hinemo/a7911';
import { A7912, A7912POI } from '../../utils/catalog/hinemo/a7912';




import { BLOOMIN } from '../../utils/catalog/bloomin/bloomin';
import { A7730, A7730POI } from '../../utils/catalog/bloomin/a7730';
import { A7731, A7731POI } from '../../utils/catalog/bloomin/a7731';
import { A7732, A7732POI } from '../../utils/catalog/bloomin/a7732';
import { A7733, A7733POI } from '../../utils/catalog/bloomin/a7733';
import { A7736, A7736POI } from '../../utils/catalog/bloomin/a7736';
import { A7737, A7737POI } from '../../utils/catalog/bloomin/a7737';
import { A7739, A7739POI } from '../../utils/catalog/bloomin/a7739';
import { F7737, F7737POI } from '../../utils/catalog/bloomin/f7737';
import { F7738, F7738POI } from '../../utils/catalog/bloomin/f7738';
import { F7739, F7739POI } from '../../utils/catalog/bloomin/f7739';







import { ALCLEA } from '../../utils/catalog/alclea/alclea';
import { A7940, A7940POI } from '../../utils/catalog/alclea/a7940';
import { A7941, A7941POI } from '../../utils/catalog/alclea/a7941';
import { A7942, A7942POI } from '../../utils/catalog/alclea/a7942';
import { F7740, F7740POI } from '../../utils/catalog/alclea/f7740';
import { HM511, HM511POI } from '../../utils/catalog/alclea/hm511';
import { HM521, HM521POI } from '../../utils/catalog/alclea/hm521';







import { NOTEBOOK } from '../../utils/catalog/note-book/note-book';
import { N2993, N2993POI } from '../../utils/catalog/note-book/n2993';







import { CUBEFIZ } from '../../utils/catalog/cubefiz/cubefiz';
import { A6005, A6005POI } from '../../utils/catalog/cubefiz/a6005';
import { N2658, N2658POI } from '../../utils/catalog/cubefiz/n2658';
import { N2641, N2641POI } from '../../utils/catalog/cubefiz/n2641';
import { N2608, N2608POI } from '../../utils/catalog/cubefiz/n2608';
import { N2611, N2611POI } from '../../utils/catalog/cubefiz/n2611';





import { HIRAKUNO } from '../../utils/catalog/hirakuno/hirakuno';
import { N1670, N1670POI } from '../../utils/catalog/hirakuno/n1670';
import { N1671, N1671POI } from '../../utils/catalog/hirakuno/n1671';
import { N1672, N1672POI } from '../../utils/catalog/hirakuno/n1672';
import { N1673, N1673POI } from '../../utils/catalog/hirakuno/n1673';
import { N1674, N1674POI } from '../../utils/catalog/hirakuno/n1674';
import { N1677, N1677POI } from '../../utils/catalog/hirakuno/n1677';
import { N1678, N1678POI } from '../../utils/catalog/hirakuno/n1678';






import { SOFFICE } from '../../utils/catalog/soffice/soffice';
import { N3100, N3100POI } from '../../utils/catalog/soffice/n3100';
import { N3101, N3101POI } from '../../utils/catalog/soffice/n3101';
import { N3103, N3103POI } from '../../utils/catalog/soffice/n3103';
import { N3104, N3104POI } from '../../utils/catalog/soffice/n3104';










import { PASTELLO } from '../../utils/catalog/pastello/pastello';
import { N1961, N1961POI } from '../../utils/catalog/pastello/n1961';
import { N1908, N1908POI } from '../../utils/catalog/pastello/n1908';
import { N1969, N1969POI } from '../../utils/catalog/pastello/n1969';
import { N1958, N1958POI } from '../../utils/catalog/pastello/n1958';
import { N1964, N1964POI } from '../../utils/catalog/pastello/n1964';



import { NYANSCENE } from '../../utils/catalog/nyan-scene/nyan-scene';
import { A2220, A2220POI } from '../../utils/catalog/nyan-scene/a2220';
import { A2221, A2221POI } from '../../utils/catalog/nyan-scene/a2221';
import { A2222, A2222POI } from '../../utils/catalog/nyan-scene/a2222';
import { A2223, A2223POI } from '../../utils/catalog/nyan-scene/a2223';
import { A2224, A2224POI } from '../../utils/catalog/nyan-scene/a2224';
import { A2225, A2225POI } from '../../utils/catalog/nyan-scene/a2225';
import { A2226, A2226POI } from '../../utils/catalog/nyan-scene/a2226';




import { SFG } from '../../utils/catalog/sfg/sfg';
import { A3200, A3200POI } from '../../utils/catalog/sfg/a3200';
import { A3201, A3201POI } from '../../utils/catalog/sfg/a3201';
import { A3202, A3202POI } from '../../utils/catalog/sfg/a3202';
import { A3203, A3203POI } from '../../utils/catalog/sfg/a3203';
import { A3204, A3204POI } from '../../utils/catalog/sfg/a3204';
import { A3205, A3205POI } from '../../utils/catalog/sfg/a3205';
import { A3206, A3206POI } from '../../utils/catalog/sfg/a3206';
import { A3207, A3207POI } from '../../utils/catalog/sfg/a3207';
import { A3208, A3208POI } from '../../utils/catalog/sfg/a3208';
import { A3209, A3209POI } from '../../utils/catalog/sfg/a3209';
import { A3220, A3220POI } from '../../utils/catalog/sfg/a3220';
import { A3221, A3221POI } from '../../utils/catalog/sfg/a3221';
import { A3222, A3222POI } from '../../utils/catalog/sfg/a3222';
import { A3223, A3223POI } from '../../utils/catalog/sfg/a3223';








function App() {


  const [catalogList, setCatalogList] = useState(CATALOG);
  const [cards, setCards] = useState(CARDS);


  const [punilabo, setPunilabo] = useState(PUNILABO);

  const [a7712, setA7712] = useState(A7712);
  const [a7712poi, setA7712poi] = useState(A7712POI);

  const [a7713, setA7713] = useState(A7713);
  const [a7713poi, setA7713poi] = useState(A7713POI);

  const [a7714, setA7714] = useState(A7714);
  const [a7714poi, setA7714poi] = useState(A7714POI);

  const [a7716, setA7716] = useState(A7716);
  const [a7716poi, setA7716poi] = useState(A7716POI);

  const [a7717, setA7717] = useState(A7717);
  const [a7717poi, setA7717poi] = useState(A7717POI);

  const [a7718, setA7718] = useState(A7718);
  const [a7718poi, setA7718poi] = useState(A7718POI);

  const [a7720, setA7720] = useState(A7720);
  const [a7720poi, setA7720poi] = useState(A7720POI);

  const [a7721, setA7721] = useState(A7721);
  const [a7721poi, setA7721poi] = useState(A7721POI);

  const [a7722, setA7722] = useState(A7722);
  const [a7722poi, setA7722poi] = useState(A7722POI);

  const [a7723, setA7723] = useState(A7723);
  const [a7723poi, setA7723poi] = useState(A7723POI);

  const [a7724, setA7724] = useState(A7724);
  const [a7724poi, setA7724poi] = useState(A7724POI);

  const [a7725, setA7725] = useState(A7725);
  const [a7725poi, setA7725poi] = useState(A7725POI);

  const [a7727, setA7727] = useState(A7727);
  const [a7727poi, setA7727poi] = useState(A7727POI);

  const [a7728, setA7728] = useState(A7728);
  const [a7728poi, setA7728poi] = useState(A7728POI);

  const [a7780, setA7780] = useState(A7780);
  const [a7780poi, setA7780poi] = useState(A7780POI);

  const [a7781, setA7781] = useState(A7781);
  const [a7781poi, setA7781poi] = useState(A7781POI);

  const [a7782, setA7782] = useState(A7782);
  const [a7782poi, setA7782poi] = useState(A7782POI);

  const [a7783, setA7783] = useState(A7783);
  const [a7783poi, setA7783poi] = useState(A7783POI);

  const [a7784, setA7784] = useState(A7784);
  const [a7784poi, setA7784poi] = useState(A7784POI);

  const [a7785, setA7785] = useState(A7785);
  const [a7785poi, setA7785poi] = useState(A7785POI);

  const [a7800, setA7800] = useState(A7800);
  const [a7800poi, setA7800poi] = useState(A7800POI);

  const [a7803, setA7803] = useState(A7803);
  const [a7803poi, setA7803poi] = useState(A7803POI);

  const [a7804, setA7804] = useState(A7804);
  const [a7804poi, setA7804poi] = useState(A7804POI);








  const [altna, setAltna] = useState(ALTNA);

  const [a7760, setA7760] = useState(A7760);
  const [a7760poi, setA7760poi] = useState(A7760POI);

  const [a7750, setA7750] = useState(A7750);
  const [a7750poi, setA7750poi] = useState(A7750POI);

  const [a7751, setA7751] = useState(A7751);
  const [a7751poi, setA7751poi] = useState(A7751POI);

  const [a7752, setA7752] = useState(A7752);
  const [a7752poi, setA7752poi] = useState(A7752POI);

  const [a7773, setA7773] = useState(A7773);
  const [a7773poi, setA7773poi] = useState(A7773POI);

  const [a7791, setA7791] = useState(A7791);
  const [a7791poi, setA7791poi] = useState(A7791POI);

  const [a7767, setA7767] = useState(A7767);
  const [a7767poi, setA7767poi] = useState(A7767POI);

  const [a7768, setA7768] = useState(A7768);
  const [a7768poi, setA7768poi] = useState(A7768POI);

  const [a7790, setA7790] = useState(A7790);
  const [a7790poi, setA7790poi] = useState(A7790POI);

  const [a7764, setA7764] = useState(A7764);
  const [a7764poi, setA7764poi] = useState(A7764POI);

  const [a7765, setA7765] = useState(A7765);
  const [a7765poi, setA7765poi] = useState(A7765POI);

  const [a7759, setA7759] = useState(A7759);
  const [a7759poi, setA7759poi] = useState(A7759POI);









  const [smartfit, setSmartfit] = useState(SMARTFIT);

  const [a8800, setA8800] = useState(A8800);
  const [a8800poi, setA8800poi] = useState(A8800POI);

  const [a7701, setA7701] = useState(A7701);
  const [a7701poi, setA7701poi] = useState(A7701POI);

  const [a7702, setA7702] = useState(A7702);
  const [a7702poi, setA7702poi] = useState(A7702POI);

  const [a7574, setA7574] = useState(A7574);
  const [a7574poi, setA7574poi] = useState(A7574POI);

  const [a7575, setA7575] = useState(A7575);
  const [a7575poi, setA7575poi] = useState(A7575POI);

  const [a7576, setA7576] = useState(A7576);
  const [a7576poi, setA7576poi] = useState(A7576POI);

  const [a7577, setA7577] = useState(A7577);
  const [a7577poi, setA7577poi] = useState(A7577POI);

  const [n1627, setN1627] = useState(N1627);
  const [n1627poi, setN1627poi] = useState(N1627POI);

  const [n1647, setN1647] = useState(N1647);
  const [n1647poi, setN1647poi] = useState(N1647POI);

  const [a7585, setA7585] = useState(A7585);
  const [a7585poi, setA7585poi] = useState(A7585POI);

  const [a7581, setA7581] = useState(A7581);
  const [a7581poi, setA7581poi] = useState(A7581POI);

  const [a7582, setA7582] = useState(A7582);
  const [a7582poi, setA7582poi] = useState(A7582POI);

  const [a7583, setA7583] = useState(A7583);
  const [a7583poi, setA7583poi] = useState(A7583POI);

  const [a7595, setA7595] = useState(A7595);
  const [a7595poi, setA7595poi] = useState(A7595POI);

  const [a7650, setA7650] = useState(A7650);
  const [a7650poi, setA7650poi] = useState(A7650POI);

  const [a7651, setA7651] = useState(A7651);
  const [a7651poi, setA7651poi] = useState(A7651POI);

  const [a7554, setA7554] = useState(A7554);
  const [a7554poi, setA7554poi] = useState(A7554POI);

  const [a7558, setA7558] = useState(A7558);
  const [a7558poi, setA7558poi] = useState(A7558POI);

  const [a7559, setA7559] = useState(A7559);
  const [a7559poi, setA7559poi] = useState(A7559POI);

  const [a7551, setA7551] = useState(A7551);
  const [a7551poi, setA7551poi] = useState(A7551POI);

  const [a7552, setA7552] = useState(A7552);
  const [a7552poi, setA7552poi] = useState(A7552POI);

  const [a7555, setA7555] = useState(A7555);
  const [a7555poi, setA7555poi] = useState(A7555POI);

  const [a7556, setA7556] = useState(A7556);
  const [a7556poi, setA7556poi] = useState(A7556POI);

  const [a7557, setA7557] = useState(A7557);
  const [a7557poi, setA7557poi] = useState(A7557POI);

  const [a7652, setA7652] = useState(A7652);
  const [a7652poi, setA7652poi] = useState(A7652POI);

  const [a7653, setA7653] = useState(A7653);
  const [a7653poi, setA7653poi] = useState(A7653POI);









  const [actact, setActact] = useState(ACTACT);

  const [a7694, setA7694] = useState(A7694);
  const [a7694poi, setA7694poi] = useState(A7694POI);

  const [a7695, setA7695] = useState(A7695);
  const [a7695poi, setA7695poi] = useState(A7695POI);

  const [a7670, setA7670] = useState(A7670);
  const [a7670poi, setA7670poi] = useState(A7670POI);

  const [a7672, setA7672] = useState(A7672);
  const [a7672poi, setA7672poi] = useState(A7672POI);

  const [a7680, setA7680] = useState(A7680);
  const [a7680poi, setA7680poi] = useState(A7680POI);

  const [a7681, setA7681] = useState(A7681);
  const [a7681poi, setA7681poi] = useState(A7681POI);

  const [a7682, setA7682] = useState(A7682);
  const [a7682poi, setA7682poi] = useState(A7682POI);

  const [a7683, setA7683] = useState(A7683);
  const [a7683poi, setA7683poi] = useState(A7683POI);

  const [a7686, setA7686] = useState(A7686);
  const [a7686poi, setA7686poi] = useState(A7686POI);

  const [a7687, setA7687] = useState(A7687);
  const [a7687poi, setA7687poi] = useState(A7687POI);

  const [a7688, setA7688] = useState(A7688);
  const [a7688poi, setA7688poi] = useState(A7688POI);






  const [hinemo, setHinemo] = useState(HINEMO);

  const [a7900, setA7900] = useState(A7900);
  const [a7900poi, setA7900poi] = useState(A7900POI);

  const [a7901, setA7901] = useState(A7901);
  const [a7901poi, setA7901poi] = useState(A7901POI);

  const [a7902, setA7902] = useState(A7902);
  const [a7902poi, setA7902poi] = useState(A7902POI);

  const [a7910, setA7910] = useState(A7910);
  const [a7910poi, setA7910poi] = useState(A7910POI);

  const [a7911, setA7911] = useState(A7911);
  const [a7911poi, setA7911poi] = useState(A7911POI);

  const [a7912, setA7912] = useState(A7912);
  const [a7912poi, setA7912poi] = useState(A7912POI);





  const [bloomin, setBloomin] = useState(BLOOMIN);

  const [a7730, setA7730] = useState(A7730);
  const [a7730poi, setA7730poi] = useState(A7730POI);

  const [a7731, setA7731] = useState(A7731);
  const [a7731poi, setA7731poi] = useState(A7731POI);

  const [a7732, setA7732] = useState(A7732);
  const [a7732poi, setA7732poi] = useState(A7732POI);

  const [a7733, setA7733] = useState(A7733);
  const [a7733poi, setA7733poi] = useState(A7733POI);

  const [a7736, setA7736] = useState(A7736);
  const [a7736poi, setA7736poi] = useState(A7736POI);

  const [a7737, setA7737] = useState(A7737);
  const [a7737poi, setA7737poi] = useState(A7737POI);

  const [a7739, setA7739] = useState(A7739);
  const [a7739poi, setA7739poi] = useState(A7739POI);

  const [f7737, setF7737] = useState(F7737);
  const [f7737poi, setF7737poi] = useState(F7737POI);

  const [f7738, setF7738] = useState(F7738);
  const [f7738poi, setF7738poi] = useState(F7738POI);

  const [f7739, setF7739] = useState(F7739);
  const [f7739poi, setF7739poi] = useState(F7739POI);






  const [alclea, setAlclea] = useState(ALCLEA);

  const [a7940, setA7940] = useState(A7940);
  const [a7940poi, setA7940poi] = useState(A7940POI);

  const [a7941, setA7941] = useState(A7941);
  const [a7941poi, setA7941poi] = useState(A7941POI);

  const [a7942, setA7942] = useState(A7942);
  const [a7942poi, setA7942poi] = useState(A7942POI);

  const [f7740, setF7740] = useState(F7740);
  const [f7740poi, setF7740poi] = useState(F7740POI);

  const [hm511, setHm511] = useState(HM511);
  const [hm511poi, setHm511poi] = useState(HM511POI);

  const [hm521, setHm521] = useState(HM521);
  const [hm521poi, setHm521poi] = useState(HM521POI);









  const [notebook, setNotebook] = useState(NOTEBOOK);

  const [n2993, setN2993] = useState(N2993);
  const [n2993poi, setN2993poi] = useState(N2993POI);








  const [cubefiz, setCubefiz] = useState(CUBEFIZ);

  const [a6005, setA6005] = useState(A6005);
  const [a6005poi, setA6005poi] = useState(A6005POI);

  const [n2658, setN2658] = useState(N2658);
  const [n2658poi, setN2658poi] = useState(N2658POI);

  const [n2641, setN2641] = useState(N2641);
  const [n2641poi, setN2641poi] = useState(N2641POI);

  const [n2608, setN2608] = useState(N2608);
  const [n2608poi, setN2608poi] = useState(N2608POI);

  const [n2611, setN2611] = useState(N2611);
  const [n2611poi, setN2611poi] = useState(N2611POI);











  const [hirakuno, setHirakuno] = useState(HIRAKUNO);

  const [n1670, setN1670] = useState(N1670);
  const [n1670poi, setN1670poi] = useState(N1670POI);

  const [n1671, setN1671] = useState(N1671);
  const [n1671poi, setN1671poi] = useState(N1671POI);

  const [n1672, setN1672] = useState(N1672);
  const [n1672poi, setN1672poi] = useState(N1672POI);

  const [n1673, setN1673] = useState(N1673);
  const [n1673poi, setN1673poi] = useState(N1673POI);

  const [n1674, setN1674] = useState(N1674);
  const [n1674poi, setN1674poi] = useState(N1674POI);

  const [n1677, setN1677] = useState(N1677);
  const [n1677poi, setN1677poi] = useState(N1677POI);

  const [n1678, setN1678] = useState(N1678);
  const [n1678poi, setN1678poi] = useState(N1678POI);












  const [soffice, setSoffice] = useState(SOFFICE);

  const [n3100, setN3100] = useState(N3100);
  const [n3100poi, setN3100poi] = useState(N3100POI);

  const [n3101, setN3101] = useState(N3101);
  const [n3101poi, setN3101poi] = useState(N3101POI);

  const [n3103, setN3103] = useState(N3103);
  const [n3103poi, setN3103poi] = useState(N3103POI);

  const [n3104, setN3104] = useState(N3104);
  const [n3104poi, setN3104poi] = useState(N3104POI);








  const [pastello, setPastello] = useState(PASTELLO);

  const [n1961, setN1961] = useState(N1961);
  const [n1961poi, setN1961poi] = useState(N1961POI);

  const [n1908, setN1908] = useState(N1908);
  const [n1908poi, setN1908poi] = useState(N1908POI);

  const [n1969, setN1969] = useState(N1969);
  const [n1969poi, setN1969poi] = useState(N1969POI);

  const [n1958, setN1958] = useState(N1958);
  const [n1958poi, setN1958poi] = useState(N1958POI);

  const [n1964, setN1964] = useState(N1964);
  const [n1964poi, setN1964poi] = useState(N1964POI);






  const [nynescene, setNynescene] = useState(NYANSCENE);

  const [a2220, setA2220] = useState(A2220);
  const [a2220poi, setA2220poi] = useState(A2220POI);

  const [a2221, setA2221] = useState(A2221);
  const [a2221poi, setA2221poi] = useState(A2221POI);

  const [a2222, setA2222] = useState(A2222);
  const [a2222poi, setA2222poi] = useState(A2222POI);

  const [a2223, setA2223] = useState(A2223);
  const [a2223poi, setA2223poi] = useState(A2223POI);

  const [a2224, setA2224] = useState(A2224);
  const [a2224poi, setA2224poi] = useState(A2224POI);

  const [a2225, setA2225] = useState(A2225);
  const [a2225poi, setA2225poi] = useState(A2225POI);

  const [a2226, setA2226] = useState(A2226);
  const [a2226poi, setA2226poi] = useState(A2226POI);




  const [sfg, setSfg] = useState(SFG);

  const [a3200, setA3200] = useState(A3200);
  const [a3200poi, setA3200poi] = useState(A3200POI);

  const [a3201, setA3201] = useState(A3201);
  const [a3201poi, setA3201poi] = useState(A3201POI);

  const [a3202, setA3202] = useState(A3202);
  const [a3202poi, setA3202poi] = useState(A3202POI);

  const [a3203, setA3203] = useState(A3203);
  const [a3203poi, setA3203poi] = useState(A3203POI);

  const [a3204, setA3204] = useState(A3204);
  const [a3204poi, setA3204poi] = useState(A3204POI);

  const [a3205, setA3205] = useState(A3205);
  const [a3205poi, setA3205poi] = useState(A3205POI);

  const [a3206, setA3206] = useState(A3206);
  const [a3206poi, setA3206poi] = useState(A3206POI);

  const [a3207, setA3207] = useState(A3207);
  const [a3207poi, setA3207poi] = useState(A3207POI);

  const [a3208, setA3208] = useState(A3208);
  const [a3208poi, setA3208poi] = useState(A3208POI);

  const [a3209, setA3209] = useState(A3209);
  const [a3209poi, setA3209poi] = useState(A3209POI);

  const [a3220, setA3220] = useState(A3220);
  const [a3220poi, setA3220poi] = useState(A3220POI);

  const [a3221, setA3221] = useState(A3221);
  const [a3221poi, setA3221poi] = useState(A3221POI);

  const [a3222, setA3222] = useState(A3222);
  const [a3222poi, setA3222poi] = useState(A3222POI);

  const [a3223, setA3223] = useState(A3223);
  const [a3223poi, setA3223poi] = useState(A3223POI);











  return (
    <div className='page'>
      <Header />

      <Navigation />







      <Routes>


        <Route
          path='/'
          element={
            <>

              <div className='cont'>

                <SimpleSlider />

              </div>
              <Main

              />



            </>

          }
        />



        <Route
          path='/catalog'
          element={
            <CatalogImg
              catalogList={catalogList}
              catalogItem={cards}
              title={'Каталог'}
            />
          }
        />



        <Route
          path='/brend'
          element={
            <Brend />
          }
        />


        <Route
          path='/reviews'
          element={
            <Reviews />
          }
        />


        <Route
          path='/gallery'
          element={
            <Gallery />
          }
        />



        <Route
          path='/contacts'
          element={
            <Contacts />
          }
        />






        <Route
          path='/catalog/punilabo'
          element={
            <CatalogImg
              catalogList={catalogList}
              catalogItem={punilabo}
              title={'PuniLabo'}
            />
          }
        />

        <Route
          path='/catalog/punilabo/a7712'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7712}
              title={'Пенал-тубус малый А-7712'}
              titlepoi={'Фото'}
              imagespoi={a7712poi}
              video={'https://www.youtube.com/embed/fOX_oo534IE?si=cnJtg-tlTgN9ZYI1'}
            />
          }
        />

        <Route
          path='/catalog/punilabo/a7713'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7713}
              title={'Держатель для смартфона А-7713'}
              titlepoi={'Фото'}
              imagespoi={a7713poi}
            />
          }
        />

        <Route
          path='/catalog/punilabo/a7714'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7714}
              title={'Пенал-тубус большой А-7714'}
              titlepoi={'Фото'}
              imagespoi={a7714poi}
              video={'https://www.youtube.com/embed/fOX_oo534IE?si=cnJtg-tlTgN9ZYI1'}
            />
          }
        />

        <Route
          path='/catalog/punilabo/a7716'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7716}
              title={'Пенал-косметичка А-7716'}
              titlepoi={'Фото'}
              imagespoi={a7716poi}
            />
          }
        />

        <Route
          path='/catalog/punilabo/a7717'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7717}
              title={'Пенал узкий, длинный А-7717'}
              titlepoi={'Фото'}
              imagespoi={a7717poi}
            />
          }
        />

        <Route
          path='/catalog/punilabo/a7718'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7718}
              title={'Брелок с звуковой сигнализацией А-7718'}
              titlepoi={'Фото'}
              imagespoi={a7718poi}
            />
          }
        />

        <Route
          path='/catalog/punilabo/a7720'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7720}
              title={'Пенал с ремешком А-7720'}
              titlepoi={'Фото'}
              imagespoi={a7720poi}
              video={'https://www.youtube.com/embed/fL1hachuCPg?si=2dwFT_k3cNuWtH-0'}
            />
          }
        />

        <Route
          path='/catalog/punilabo/a7721'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7721}
              title={'Магнитная клипса А-7721'}
              titlepoi={'Фото'}
              imagespoi={a7721poi}
              video={'https://www.youtube.com/embed/AmRlGJdxUJA?si=R1TaC8oGwjtV7TCm'}
            />
          }
        />

        <Route
          path='/catalog/punilabo/a7722'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7722}
              title={'Стягиватель А-7722'}
              titlepoi={'Фото'}
              imagespoi={a7722poi}
              video={'https://www.youtube.com/embed/9SoX0wdTt8s?si=4u4s2SaYqpJQ4H7v'}
            />
          }
        />

        <Route
          path='/catalog/punilabo/a7723'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7723}
              title={'Блок для записей А-7723'}
              titlepoi={'Фото'}
              imagespoi={a7723poi}
              video={'https://www.youtube.com/embed/UEEzqPPGG1M?si=_FEOt_fBpl5xewMD'}
            />
          }
        />

        <Route
          path='/catalog/punilabo/a7724'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7724}
              title={'Блок для записей большой А-7724'}
              titlepoi={'Фото'}
              imagespoi={a7724poi}
              video={'https://www.youtube.com/embed/UEEzqPPGG1M?si=_FEOt_fBpl5xewMD'}
            />
          }
        />

        <Route
          path='/catalog/punilabo/a7725'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7725}
              title={'Магнит канцелярский А-7725'}
              titlepoi={'Фото'}
              imagespoi={a7725poi}
              video={'https://www.youtube.com/embed/BZu1gyMWEts?si=Rjvm5xzbKNBRDnY-'}
            />
          }
        />

        <Route
          path='/catalog/punilabo/a7727'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7727}
              title={'Держатель для кабеля А-7727'}
              titlepoi={'Фото'}
              imagespoi={a7727poi}
              video={'https://www.youtube.com/embed/dslm2nFuZg4?si=YUt8djzFlSYqDW_F'}
            />
          }
        />

        <Route
          path='/catalog/punilabo/a7728'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7728}
              title={'Очиститель экрана А-7728'}
              titlepoi={'Фото'}
              imagespoi={a7728poi}
              video={'https://www.youtube.com/embed/nEZK8pOC7Y4?si=POG5fKtg6C3bZpMk'}
            />
          }
        />

        <Route
          path='/catalog/punilabo/a7780'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7780}
              title={'Пенал лежащий, мини А-7780'}
              titlepoi={'Фото'}
              imagespoi={a7780poi}
              video={'https://www.youtube.com/embed/Nhec8pliPzQ?si=eGokU7EOt3yk8c39'}
            />
          }
        />

        <Route
          path='/catalog/punilabo/a7781'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7781}
              title={'Пенал лежащий, длинный А-7781'}
              titlepoi={'Фото'}
              imagespoi={a7781poi}
              video={'https://www.youtube.com/embed/0elLQ8DKOz0?si=2kJ9xzWb8gCZIOI7'}
            />
          }
        />

        <Route
          path='/catalog/punilabo/a7782'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7782}
              title={'Пенал яйцо А-7782'}
              titlepoi={'Фото'}
              imagespoi={a7782poi}
              video={'https://www.youtube.com/embed/ZlqSaHguvHw?si=wkLhOhoQTxvjA3wP'}
            />
          }
        />

        <Route
          path='/catalog/punilabo/a7783'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7783}
              title={'Пенал яйцо, большой А-7783'}
              titlepoi={'Фото'}
              imagespoi={a7783poi}
              video={'https://www.youtube.com/embed/ZlqSaHguvHw?si=wkLhOhoQTxvjA3wP'}
            />
          }
        />

        <Route
          path='/catalog/punilabo/a7784'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7784}
              title={'Кошелек А-7784'}
              titlepoi={'Фото'}
              imagespoi={a7784poi}
              video={'https://www.youtube.com/embed/kiYj3mI62cs?si=z4Nwjdt1ER4p9eoT'}
            />
          }
        />

        <Route
          path='/catalog/punilabo/a7785'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7785}
              title={'Ключница А-7785'}
              titlepoi={'Фото'}
              imagespoi={a7785poi}
              video={'https://www.youtube.com/embed/7wXdVB0kNdE?si=ld-_StSRQHapJJpL'}
            />
          }
        />

        <Route
          path='/catalog/punilabo/a7800'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7800}
              title={'Пенал лежащий, большой А-7800'}
              titlepoi={'Фото'}
              imagespoi={a7800poi}
              video={'https://www.youtube.com/embed/0elLQ8DKOz0?si=2kJ9xzWb8gCZIOI7'}
            />
          }
        />

        <Route
          path='/catalog/punilabo/a7803'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7803}
              title={'Ножницы А-7803'}
              titlepoi={'Фото'}
              imagespoi={a7803poi}
              video={'https://www.youtube.com/embed/vIcIO1BLH7M?si=PEPomAl76kQlEGhi'}
            />
          }
        />

        <Route
          path='/catalog/punilabo/a7804'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7804}
              title={'Бейджик А-7804'}
              titlepoi={'Фото'}
              imagespoi={a7804poi}
              video={'https://www.youtube.com/embed/ex1IeNhk9EY?si=zNJ4m2suhRoxFtrt'}
            />
          }
        />















        <Route
          path='/catalog/altna'
          element={
            <CatalogImg
              catalogList={catalogList}
              catalogItem={altna}
              title={'ALTNA'}
            />
          }
        />

        <Route
          path='/catalog/altna/a7760'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7760}
              title={'Бизнес-сумка А-7760'}
              titlepoi={'Фото'}
              imagespoi={a7760poi}
              video={'https://www.youtube.com/embed/O_ikXYo57NM?si=siGeoKxQhxiwVrg0'}
            />
          }
        />

        <Route
          path='/catalog/altna/a7750'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7750}
              title={'Сумка-органайзер горизонтальная А-7750'}
              titlepoi={'Фото'}
              imagespoi={a7750poi}
              video={'https://www.youtube.com/embed/E-5H0tMbUfU?si=JAtPwaaMBmQAdnDy'}
            />
          }
        />

        <Route
          path='/catalog/altna/a7751'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7751}
              title={'Сумка-органайзер вертикальная А-7751'}
              titlepoi={'Фото'}
              imagespoi={a7751poi}
              video={'https://www.youtube.com/embed/E-5H0tMbUfU?si=JAtPwaaMBmQAdnDy'}
            />
          }
        />

        <Route
          path='/catalog/altna/a7752'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7752}
              title={'Сумка-органайзер А-7752'}
              titlepoi={'Фото'}
              imagespoi={a7752poi}
            />
          }
        />

        <Route
          path='/catalog/altna/a7773'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7773}
              title={'Папка-сумка складная А-7773'}
              titlepoi={'Фото'}
              imagespoi={a7773poi}
              video={'https://www.youtube.com/embed/TrcjL_vDHYA?si=YDMawAP7OcDzKhS_'}
            />
          }
        />


        <Route
          path='/catalog/altna/a7791'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7791}
              title={'Крючек для сумки А-7791'}
              titlepoi={'Фото'}
              imagespoi={a7791poi}
            />
          }
        />

        <Route
          path='/catalog/altna/a7767'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7767}
              title={'Папка-сумка органайзер M А-7767'}
              titlepoi={'Фото'}
              imagespoi={a7767poi}
              video={'https://www.youtube.com/embed/Usf58j_SgN0?si=AVrB96zXfSakG-vI'}
            />
          }
        />

        <Route
          path='/catalog/altna/a7768'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7768}
              title={'Папка-сумка органайзер L А-7768'}
              titlepoi={'Фото'}
              imagespoi={a7768poi}
              video={'https://www.youtube.com/embed/Usf58j_SgN0?si=AVrB96zXfSakG-vI'}
            />
          }
        />

        <Route
          path='/catalog/altna/a7790'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7790}
              title={'Держатель смартфона А-7790'}
              titlepoi={'Фото'}
              imagespoi={a7790poi}
            />
          }
        />


        <Route
          path='/catalog/altna/a7764'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7764}
              title={'Пенал-органайзер А-7764'}
              titlepoi={'Фото'}
              imagespoi={a7764poi}
              video={'https://www.youtube.com/embed/dpcRKOYynC4?si=TLYnoGtPUX5WiEz4'}
            />
          }
        />


        <Route
          path='/catalog/altna/a7765'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7765}
              title={'Пенал-органайзер большой А-7765'}
              titlepoi={'Фото'}
              imagespoi={a7765poi}
              video={'https://www.youtube.com/embed/dpcRKOYynC4?si=TLYnoGtPUX5WiEz4'}
            />
          }
        />

        <Route
          path='/catalog/altna/a7759'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7759}
              title={'Пенал-тубус с магнитной крышкой А-7759'}
              titlepoi={'Фото'}
              imagespoi={a7759poi}
              video={'https://www.youtube.com/embed/0mlHqGhXYIU?si=L0xEx8PAgovzcB-L'}
            />
          }
        />











        <Route
          path='/catalog/smartfit'
          element={
            <CatalogImg
              catalogList={catalogList}
              catalogItem={smartfit}
              title={'Smart Fit'}
            />
          }
        />

        <Route
          path='/catalog/smartfit/a8800'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a8800}
              title={'Папка-сумка складная А-8800'}
              titlepoi={'Фото'}
              imagespoi={a8800poi}
            />
          }
        />

        <Route
          path='/catalog/smartfit/a7701'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7701}
              title={'Клатч А-7701'}
              titlepoi={'Фото'}
              imagespoi={a7701poi}
            />
          }
        />


        <Route
          path='/catalog/smartfit/a7702'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7702}
              title={'Клатч большой А-7702'}
              titlepoi={'Фото'}
              imagespoi={a7702poi}
            />
          }
        />

        <Route
          path='/catalog/smartfit/a7574'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7574}
              title={'Сумка поясная А-7574'}
              titlepoi={'Фото'}
              imagespoi={a7574poi}
              video={'https://www.youtube.com/embed/6UzYvlKQnMY?si=PDiDjNEdwFJcvVB1'}

            />
          }
        />

        <Route
          path='/catalog/smartfit/a7575'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7575}
              title={'Папка-сумка для гаджетов А5'}
              titlepoi={'Фото'}
              imagespoi={a7575poi}
              video={'https://www.youtube.com/embed/Vn6gVXFlTCg?si=ClfyIgaEVV-x6buz'}

            />
          }
        />

        <Route
          path='/catalog/smartfit/a7576'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7576}
              title={'Папка-сумка для гаджетов B5'}
              titlepoi={'Фото'}
              imagespoi={a7576poi}
              video={'https://www.youtube.com/embed/Vn6gVXFlTCg?si=ClfyIgaEVV-x6buz'}

            />
          }
        />


        <Route
          path='/catalog/smartfit/a7577'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7577}
              title={'Папка-сумка для гаджетов A4'}
              titlepoi={'Фото'}
              imagespoi={a7577poi}
              video={'https://www.youtube.com/embed/Vn6gVXFlTCg?si=ClfyIgaEVV-x6buz'}

            />
          }
        />

        <Route
          path='/catalog/smartfit/n1627'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={n1627}
              title={'Блокнот-органайзер B5'}
              titlepoi={'Фото'}
              imagespoi={n1627poi}
              video={'https://www.youtube.com/embed/35bgzBlYywg?si=zu5-0mzcRP3SsXLp'}

            />
          }
        />


        <Route
          path='/catalog/smartfit/n1647'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={n1647}
              title={'Блокнот-органайзер A5'}
              titlepoi={'Фото'}
              imagespoi={n1647poi}
              video={'https://www.youtube.com/embed/35bgzBlYywg?si=zu5-0mzcRP3SsXLp'}

            />
          }
        />


        <Route
          path='/catalog/smartfit/a7585'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7585}
              title={'Пенал-портмоне A-7585'}
              titlepoi={'Фото'}
              imagespoi={a7585poi}

            />
          }
        />

        <Route
          path='/catalog/smartfit/a7581'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7581}
              title={'Сумка c увеличивающейся толщиной B4 A-7581'}
              titlepoi={'Фото'}
              imagespoi={a7581poi}

            />
          }
        />

        <Route
          path='/catalog/smartfit/a7582'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7582}
              title={'Сумка c увеличивающейся толщиной B4 A-7582'}
              titlepoi={'Фото'}
              imagespoi={a7582poi}

            />
          }
        />

        <Route
          path='/catalog/smartfit/a7583'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7583}
              title={'Сумка-шоппер B4/A4 A-7583'}
              titlepoi={'Фото'}
              imagespoi={a7583poi}

            />
          }
        />

        <Route
          path='/catalog/smartfit/a7595'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7595}
              title={'Крючек для сумки A-7595'}
              titlepoi={'Фото'}
              imagespoi={a7595poi}

            />
          }
        />

        <Route
          path='/catalog/smartfit/a7650'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7650}
              title={'Сумка B4 c удлиняющейся ручкой A-7650'}
              titlepoi={'Фото'}
              imagespoi={a7650poi}

            />
          }
        />

        <Route
          path='/catalog/smartfit/a7651'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7651}
              title={'Сумка B4 c удлиняющейся ручкой широкая A-7651'}
              titlepoi={'Фото'}
              imagespoi={a7651poi}

            />
          }
        />



        <Route
          path='/catalog/smartfit/a7554'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7554}
              title={'Папка-сумка органайзер А4 A-7554'}
              titlepoi={'Фото'}
              imagespoi={a7554poi}

            />
          }
        />



        <Route
          path='/catalog/smartfit/a7558'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7558}
              title={'Папка-сумка органайзер А5 A-7558'}
              titlepoi={'Фото'}
              imagespoi={a7558poi}

            />
          }
        />



        <Route
          path='/catalog/smartfit/a7559'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7559}
              title={'Папка-сумка органайзер А4 A-7559'}
              titlepoi={'Фото'}
              imagespoi={a7559poi}

            />
          }
        />



        <Route
          path='/catalog/smartfit/a7551'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7551}
              title={'Пенал-органайзер с одним отделом A-7551'}
              titlepoi={'Фото'}
              imagespoi={a7551poi}

            />
          }
        />



        <Route
          path='/catalog/smartfit/a7552'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7552}
              title={'Пенал-органайзер с одним отделом узкий A-7552'}
              titlepoi={'Фото'}
              imagespoi={a7552poi}

            />
          }
        />

        <Route
          path='/catalog/smartfit/a7555'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7555}
              title={'Пенал-органайзер с двумя отделами A-7555'}
              titlepoi={'Фото'}
              imagespoi={a7555poi}

            />
          }
        />


        <Route
          path='/catalog/smartfit/a7556'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7556}
              title={'Пенал-органайзер с тремя отделами A-7556'}
              titlepoi={'Фото'}
              imagespoi={a7556poi}

            />
          }
        />

        <Route
          path='/catalog/smartfit/a7557'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7557}
              title={'Пенал-органайзер с двумя отделами узкий A-7557'}
              titlepoi={'Фото'}
              imagespoi={a7557poi}

            />
          }
        />

        <Route
          path='/catalog/smartfit/a7652'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7652}
              title={'Пенал плоский A-7652'}
              titlepoi={'Фото'}
              imagespoi={a7652poi}

            />
          }
        />

        <Route
          path='/catalog/smartfit/a7653'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7653}
              title={'Пенал плоский большой A-7653'}
              titlepoi={'Фото'}
              imagespoi={a7653poi}

            />
          }
        />





        <Route
          path='/catalog/actact'
          element={
            <CatalogImg
              catalogList={catalogList}
              catalogItem={actact}
              title={'ACTACT'}
            />
          }
        />


        <Route
          path='/catalog/actact/a7694'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7694}
              title={'Пенал-тубус овальный A-7694'}
              titlepoi={'Фото'}
              imagespoi={a7694poi}

            />
          }
        />


        <Route
          path='/catalog/actact/a7695'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7695}
              title={'Пенал-тубус овальный большой A-7695'}
              titlepoi={'Фото'}
              imagespoi={a7695poi}

            />
          }
        />

        <Route
          path='/catalog/actact/a7670'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7670}
              title={'Сумка-рюкзак горизонтальная A-7670'}
              titlepoi={'Фото'}
              imagespoi={a7670poi}

            />
          }
        />

        <Route
          path='/catalog/actact/a7672'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7672}
              title={'Сумка-рюкзак вертикальная A-7672'}
              titlepoi={'Фото'}
              imagespoi={a7672poi}

            />
          }
        />

        <Route
          path='/catalog/actact/a7680'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7680}
              title={'Папка-сумка органайзер горизонтальная А5 A-7680'}
              titlepoi={'Фото'}
              imagespoi={a7680poi}

            />
          }
        />



        <Route
          path='/catalog/actact/a7681'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7681}
              title={'Папка-сумка органайзер горизонтальная А4 A-7681'}
              titlepoi={'Фото'}
              imagespoi={a7681poi}

            />
          }
        />

        <Route
          path='/catalog/actact/a7682'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7682}
              title={'Папка-сумка органайзер вертикальная А5 A-7682'}
              titlepoi={'Фото'}
              imagespoi={a7682poi}

            />
          }
        />

        <Route
          path='/catalog/actact/a7683'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7683}
              title={'Папка-сумка органайзер вертикальная А4 A-7683'}
              titlepoi={'Фото'}
              imagespoi={a7683poi}

            />
          }
        />

        <Route
          path='/catalog/actact/a7686'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7686}
              title={'Пенал-органайзер дорожный А4 A-7686'}
              titlepoi={'Фото'}
              imagespoi={a7686poi}

            />
          }
        />



        <Route
          path='/catalog/actact/a7687'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7687}
              title={'Пенал-органайзер плоский A-7687'}
              titlepoi={'Фото'}
              imagespoi={a7687poi}

            />
          }
        />


        <Route
          path='/catalog/actact/a7688'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7688}
              title={'Пенал-косметичка A-7688'}
              titlepoi={'Фото'}
              imagespoi={a7688poi}

            />
          }
        />




        <Route
          path='/catalog/hinemo'
          element={
            <CatalogImg
              catalogList={catalogList}
              catalogItem={hinemo}
              title={'HINEMO'}
            />
          }
        />

        <Route
          path='/catalog/hinemo/a7900'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7900}
              title={'Пенал-косметичка M A-7900'}
              titlepoi={'Фото'}
              imagespoi={a7900poi}
              video={'https://www.youtube.com/embed/hC1MXC9oRh8?si=fmrnWqNVhOYHjimB'}

            />
          }
        />

        <Route
          path='/catalog/hinemo/a7901'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7901}
              title={'Пенал-косметичка L A-7901'}
              titlepoi={'Фото'}
              imagespoi={a7901poi}
              video={'https://www.youtube.com/embed/hC1MXC9oRh8?si=fmrnWqNVhOYHjimB'}

            />
          }
        />

        <Route
          path='/catalog/hinemo/a7902'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7902}
              title={'Пенал двухсторонний L-образный A-7902'}
              titlepoi={'Фото'}
              imagespoi={a7902poi}
              video={'https://www.youtube.com/embed/4brwa9-p3xg?si=qRi7NZHd-WMCqn24'}

            />
          }
        />

        <Route
          path='/catalog/hinemo/a7910'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7910}
              title={'Папка-сумка двухсторонняя S A-7910'}
              titlepoi={'Фото'}
              imagespoi={a7910poi}
              video={'https://www.youtube.com/embed/R9z5NCjXflc?si=TrOEa14RE86wzj9P'}

            />
          }
        />

        <Route
          path='/catalog/hinemo/a7911'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7911}
              title={'Папка-сумка двухсторонняя M A-7911'}
              titlepoi={'Фото'}
              imagespoi={a7911poi}
              video={'https://www.youtube.com/embed/R9z5NCjXflc?si=TrOEa14RE86wzj9P'}

            />
          }
        />

        <Route
          path='/catalog/hinemo/a7912'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7912}
              title={'Папка-сумка двухсторонняя L A-7912'}
              titlepoi={'Фото'}
              imagespoi={a7912poi}
              video={'https://www.youtube.com/embed/R9z5NCjXflc?si=TrOEa14RE86wzj9P'}

            />
          }
        />







        <Route
          path='/catalog/bloomin'
          element={
            <CatalogImg
              catalogList={catalogList}
              catalogItem={bloomin}
              title={'Boomin'}
            />
          }
        />

        <Route
          path='/catalog/bloomin/a7730'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7730}
              title={'Пенал футляр A-7730'}
              titlepoi={'Фото'}
              imagespoi={a7730poi}
              video={'https://www.youtube.com/embed/ZRQ0dx6ym9I?si=NvF5pCjlj6UioMVx'}

            />
          }
        />

        <Route
          path='/catalog/bloomin/a7731'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7731}
              title={'Пенал футляр большой A-7731'}
              titlepoi={'Фото'}
              imagespoi={a7731poi}
              video={'https://www.youtube.com/embed/ZRQ0dx6ym9I?si=NvF5pCjlj6UioMVx'}

            />
          }
        />

        <Route
          path='/catalog/bloomin/a7732'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7732}
              title={'Пенал-тубус A-7732'}
              titlepoi={'Фото'}
              imagespoi={a7732poi}
              video={'https://www.youtube.com/embed/wyNHPH8dsg4?si=cQFuAhFqm8WJjJAX'}

            />
          }
        />

        <Route
          path='/catalog/bloomin/a7733'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7733}
              title={'Пенал с ремешком A-7733'}
              titlepoi={'Фото'}
              imagespoi={a7733poi}

            />
          }
        />

        <Route
          path='/catalog/bloomin/a7736'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7736}
              title={'Косметичка A-7736'}
              titlepoi={'Фото'}
              imagespoi={a7736poi}
              video={'https://www.youtube.com/embed/57IYceK8O3s?si=RG2Etmjut3HALe_-'}

            />
          }
        />

        <Route
          path='/catalog/bloomin/a7737'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7737}
              title={'Сумочка-косметичка A-7737'}
              titlepoi={'Фото'}
              imagespoi={a7737poi}

            />
          }
        />

        <Route
          path='/catalog/bloomin/a7739'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7739}
              title={'Папка для документов А4 A-7739'}
              titlepoi={'Фото'}
              imagespoi={a7739poi}
              video={'https://www.youtube.com/embed/xG0cE2PEjS4?si=bgw7Oq9fUwlFPLe8'}

            />
          }
        />

        <Route
          path='/catalog/bloomin/f7737'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={f7737}
              title={'Кошелек F-7737'}
              titlepoi={'Фото'}
              imagespoi={f7737poi}

            />
          }
        />

        <Route
          path='/catalog/bloomin/f7738'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={f7738}
              title={'Пенал плоский F-7738'}
              titlepoi={'Фото'}
              imagespoi={f7738poi}

            />
          }
        />

        <Route
          path='/catalog/bloomin/f7739'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={f7739}
              title={'Папка F-7739'}
              titlepoi={'Фото'}
              imagespoi={f7739poi}

            />
          }
        />



















        <Route
          path='/catalog/aqua-drops'
          element={
            <CatalogImg
              catalogList={catalogList}
              catalogItem={[]}
              title={'AQUA DROPS'}
            />
          }
        />








        <Route
          path='/catalog/alclea'
          element={
            <CatalogImg
              catalogList={catalogList}
              catalogItem={alclea}
              title={'ALCLEA'}
            />
          }
        />

        <Route
          path='/catalog/alclea/a7940'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7940}
              title={'Планшет с антибактериальным покрытием с держателем для ручки A-7940'}
              titlepoi={'Фото'}
              imagespoi={a7940poi}

            />
          }
        />

        <Route
          path='/catalog/alclea/a7941'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7941}
              title={'Пенал раскладной, с прозрачным карманом A-7941'}
              titlepoi={'Фото'}
              imagespoi={a7941poi}

            />
          }
        />



        <Route
          path='/catalog/alclea/a7942'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a7942}
              title={'Пенал-косметичка с прозрачными карманами A-7942'}
              titlepoi={'Фото'}
              imagespoi={a7942poi}

            />
          }
        />

        <Route
          path='/catalog/alclea/f7740'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={f7740}
              title={'Папка-планшет с зажимом А4 F-7740'}
              titlepoi={'Фото'}
              imagespoi={f7740poi}

            />
          }
        />

        <Route
          path='/catalog/alclea/hm511'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={hm511}
              title={'Сумочка для лекарств и пренадлежностей S HM-511'}
              titlepoi={'Фото'}
              imagespoi={hm511poi}

            />
          }
        />



        <Route
          path='/catalog/alclea/hm521'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={hm521}
              title={'Сумочка для документов А6 HM-521'}
              titlepoi={'Фото'}
              imagespoi={hm521poi}

            />
          }
        />




















        <Route
          path='/catalog/note-book'
          element={
            <CatalogImg
              catalogList={catalogList}
              catalogItem={notebook}
              title={'Блокноты'}
            />
          }
        />


        <Route
          path='/catalog/note-book/n2993'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={n2993}
              title={'Блокнот на пружине с обложкой из искусстенной кожи формата А5 со сменными листами (50 листов)'}
              titlepoi={'Фото'}
              imagespoi={n2993poi}
              video={''}

            />
          }
        />




















        <Route
          path='/catalog/cube-fiz'
          element={
            <CatalogImg
              catalogList={catalogList}
              catalogItem={cubefiz}
              title={'CUBE FIZ'}
            />
          }
        />


        <Route
          path='/catalog/cube-fiz/a6005'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a6005}
              title={'Папка-сумка А4'}
              titlepoi={'Фото'}
              imagespoi={a6005poi}
              video={'https://www.youtube.com/embed/Xy5En5jE1-M?si=6KgoW58s2dIK0FIn'}

            />
          }
        />

        <Route
          path='/catalog/cube-fiz/n2658'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={n2658}
              title={'Блокнот перекидной на пружине формата А5 со сменными листами (30 листов) N-2658'}
              titlepoi={'Фото'}
              imagespoi={n2658poi}
              video={'https://www.youtube.com/embed/Xy5En5jE1-M?si=6KgoW58s2dIK0FIn'}

            />
          }
        />

        <Route
          path='/catalog/cube-fiz/n2641'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={n2641}
              title={'Блокнот перекидной на пружине формата А5 со сменными листами (70 листов) N-2641'}
              titlepoi={'Фото'}
              imagespoi={n2641poi}
              video={'https://www.youtube.com/embed/Xy5En5jE1-M?si=6KgoW58s2dIK0FIn'}

            />
          }
        />

        <Route
          path='/catalog/cube-fiz/n2608'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={n2608}
              title={'Блокнот перекидной на пружине формата B5 со сменными листами (30 листов) N-2608'}
              titlepoi={'Фото'}
              imagespoi={n2608poi}
              video={'https://www.youtube.com/embed/Xy5En5jE1-M?si=6KgoW58s2dIK0FIn'}

            />
          }
        />

        <Route
          path='/catalog/cube-fiz/n2611'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={n2611}
              title={'Блокнот перекидной на пружине формата B5 со сменными листами (70 листов) N-2611'}
              titlepoi={'Фото'}
              imagespoi={n2611poi}
              video={'https://www.youtube.com/embed/Xy5En5jE1-M?si=6KgoW58s2dIK0FIn'}

            />
          }
        />



















        <Route
          path='/catalog/hirakuno'
          element={
            <CatalogImg
              catalogList={catalogList}
              catalogItem={hirakuno}
              title={'Hirakuno'}
            />
          }
        />

        <Route
          path='/catalog/hirakuno/n1670'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={n1670}
              title={'Блокнот перекидной на пружине формата B7 со сменными листами (50 листов) N-1670'}
              titlepoi={'Фото'}
              imagespoi={n1670poi}
              video={'https://www.youtube.com/embed/hLiKeTuRz9s?si=PkjYlc5jRGh5KhvF'}

            />
          }
        />

        <Route
          path='/catalog/hirakuno/n1671'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={n1671}
              title={'Блокнот перекидной на пружине формата A6 со сменными листами (50 листов) N-1671'}
              titlepoi={'Фото'}
              imagespoi={n1671poi}
              video={'https://www.youtube.com/embed/hLiKeTuRz9s?si=PkjYlc5jRGh5KhvF'}

            />
          }
        />

        <Route
          path='/catalog/hirakuno/n1672'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={n1672}
              title={'Блокнот перекидной на пружине формата B6 со сменными листами (50 листов) N-1672'}
              titlepoi={'Фото'}
              imagespoi={n1672poi}
              video={'https://www.youtube.com/embed/hLiKeTuRz9s?si=PkjYlc5jRGh5KhvF'}

            />
          }
        />

        <Route
          path='/catalog/hirakuno/n1673'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={n1673}
              title={'Блокнот перекидной на пружине формата A5 со сменными листами (50 листов) N-1673'}
              titlepoi={'Фото'}
              imagespoi={n1673poi}
              video={'https://www.youtube.com/embed/hLiKeTuRz9s?si=PkjYlc5jRGh5KhvF'}

            />
          }
        />

        <Route
          path='/catalog/hirakuno/n1674'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={n1674}
              title={'Блокнот перекидной на пружине формата B5 со сменными листами (50 листов) N-1674'}
              titlepoi={'Фото'}
              imagespoi={n1674poi}
              video={'https://www.youtube.com/embed/hLiKeTuRz9s?si=PkjYlc5jRGh5KhvF'}

            />
          }
        />

        <Route
          path='/catalog/hirakuno/n1677'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={n1677}
              title={'Блокнот перекидной на пружине формата А6/А5Е со сменными листами (10 листов) N-1677'}
              titlepoi={'Фото'}
              imagespoi={n1677poi}
              video={'https://www.youtube.com/embed/1FdDP65ti_U?si=XInNIUr7VV4bzocV'}

            />
          }
        />

        <Route
          path='/catalog/hirakuno/n1678'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={n1678}
              title={'Блокнот перекидной на пружине формата B6/B5Е со сменными листами (10 листов) N-1678'}
              titlepoi={'Фото'}
              imagespoi={n1678poi}
              video={'https://www.youtube.com/embed/1FdDP65ti_U?si=XInNIUr7VV4bzocV'}

            />
          }
        />












        <Route
          path='/catalog/soffice'
          element={
            <CatalogImg
              catalogList={catalogList}
              catalogItem={soffice}
              title={'Soffice'}
            />
          }
        />


        <Route
          path='/catalog/soffice/n3100'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={n3100}
              title={'Блокнот перекидной на пружине формата B7 со сменными листами (40 листов) N-3100'}
              titlepoi={'Фото'}
              imagespoi={n3100poi}

            />
          }
        />


        <Route
          path='/catalog/soffice/n3101'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={n3101}
              title={'Блокнот перекидной на пружине формата A6 со сменными листами (30 листов) N-3101'}
              titlepoi={'Фото'}
              imagespoi={n3101poi}

            />
          }
        />

        <Route
          path='/catalog/soffice/n3103'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={n3103}
              title={'Блокнот перекидной на пружине формата A5 со сменными листами (30 листов) N-3103'}
              titlepoi={'Фото'}
              imagespoi={n3103poi}

            />
          }
        />


        <Route
          path='/catalog/soffice/n3104'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={n3104}
              title={'Блокнот перекидной на пружине формата B5 со сменными листами (30 листов) N-3104'}
              titlepoi={'Фото'}
              imagespoi={n3104poi}

            />
          }
        />







        <Route
          path='/catalog/pastello'
          element={
            <CatalogImg
              catalogList={catalogList}
              catalogItem={pastello}
              title={'Pastello'}
            />
          }
        />

        <Route
          path='/catalog/pastello/n1961'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={n1961}
              title={'Блокнот перекидной на пружине формата B7 со сменными листами (40 листов) N-1961'}
              titlepoi={'Фото'}
              imagespoi={n1961poi}
              video={'https://www.youtube.com/embed/il1A2y0jIPY?si=cE1M8MNDHLP5xNWP'}

            />
          }
        />

        <Route
          path='/catalog/pastello/n1908'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={n1908}
              title={'Блокнот перекидной на пружине формата B5 со сменными листами (30 листов) N-1908'}
              titlepoi={'Фото'}
              imagespoi={n1908poi}
              video={'https://www.youtube.com/embed/il1A2y0jIPY?si=cE1M8MNDHLP5xNWP'}

            />
          }
        />

        <Route
          path='/catalog/pastello/n1969'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={n1969}
              title={'Блокнот перекидной на пружине формата B6 со сменными листами (30 листов) N-1969'}
              titlepoi={'Фото'}
              imagespoi={n1969poi}
              video={'https://www.youtube.com/embed/il1A2y0jIPY?si=cE1M8MNDHLP5xNWP'}

            />
          }
        />

        <Route
          path='/catalog/pastello/n1958'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={n1958}
              title={'Блокнот перекидной на пружине формата A5 со сменными листами (30 листов) N-1958'}
              titlepoi={'Фото'}
              imagespoi={n1958poi}
              video={'https://www.youtube.com/embed/il1A2y0jIPY?si=cE1M8MNDHLP5xNWP'}

            />
          }
        />

        <Route
          path='/catalog/pastello/n1964'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={n1964}
              title={'Блокнот перекидной на пружине формата A6 со сменными листами (30 листов) N-1964'}
              titlepoi={'Фото'}
              imagespoi={n1964poi}
              video={'https://www.youtube.com/embed/il1A2y0jIPY?si=cE1M8MNDHLP5xNWP'}

            />
          }
        />



















        <Route
          path='/catalog/kanctovary'
          element={
            <CatalogImg
              catalogList={catalogList}
              catalogItem={[]}
              title={'Канцелярские товары'}
            />
          }
        />
















        <Route
          path='/catalog/nyan-scene'
          element={
            <CatalogImg
              catalogList={catalogList}
              catalogItem={nynescene}
              title={'Nyan Scene'}
            />
          }
        />

        <Route
          path='/catalog/nyan-scene/a2220'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a2220}
              title={'Кошелек'}
              titlepoi={'Фото'}
              imagespoi={a2220poi}


            />
          }
        />

        <Route
          path='/catalog/nyan-scene/a2221'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a2221}
              title={'Пенал-плоский'}
              titlepoi={'Фото'}
              imagespoi={a2221poi}


            />
          }
        />



        <Route
          path='/catalog/nyan-scene/a2222'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a2222}
              title={'Клатч плоский'}
              titlepoi={'Фото'}
              imagespoi={a2222poi}


            />
          }
        />

        <Route
          path='/catalog/nyan-scene/a2223'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a2223}
              title={'Пенал широкий'}
              titlepoi={'Фото'}
              imagespoi={a2223poi}


            />
          }
        />


        <Route
          path='/catalog/nyan-scene/a2224'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a2224}
              title={'Пенал-косметичка'}
              titlepoi={'Фото'}
              imagespoi={a2224poi}


            />
          }
        />


        <Route
          path='/catalog/nyan-scene/a2225'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a2225}
              title={'Сумочка кошелек с ремешком'}
              titlepoi={'Фото'}
              imagespoi={a2225poi}


            />
          }
        />


        <Route
          path='/catalog/nyan-scene/a2226'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a2226}
              title={'Сумка-органайзер для ноутбука'}
              titlepoi={'Фото'}
              imagespoi={a2226poi}


            />
          }
        />






        <Route
          path='/catalog/sfg'
          element={
            <CatalogImg
              catalogList={catalogList}
              catalogItem={sfg}
              title={'SFG'}
            />
          }
        />


        <Route
          path='/catalog/sfg/a3200'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a3200}
              title={'Пенал-органайзер'}
              titlepoi={'Фото'}
              imagespoi={a3200poi}


            />
          }
        />


        <Route
          path='/catalog/sfg/a3201'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a3201}
              title={'Пенал органайзер L-образный'}
              titlepoi={'Фото'}
              imagespoi={a3201poi}


            />
          }
        />

        <Route
          path='/catalog/sfg/a3202'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a3202}
              title={'Папка-сумка L-образная'}
              titlepoi={'Фото'}
              imagespoi={a3202poi}


            />
          }
        />


        <Route
          path='/catalog/sfg/a3203'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a3203}
              title={'Кошелек-ключница на карабине'}
              titlepoi={'Фото'}
              imagespoi={a3203poi}


            />
          }
        />


        <Route
          path='/catalog/sfg/a3204'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a3204}
              title={'Папка-сумка L-образная'}
              titlepoi={'Фото'}
              imagespoi={a3204poi}


            />
          }
        />


        <Route
          path='/catalog/sfg/a3205'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a3205}
              title={'Кошелек-ключница на карабине'}
              titlepoi={'Фото'}
              imagespoi={a3205poi}


            />
          }
        />

        <Route
          path='/catalog/sfg/a3206'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a3206}
              title={'Сумочка-месенджер с ручкой'}
              titlepoi={'Фото'}
              imagespoi={a3206poi}


            />
          }
        />

        <Route
          path='/catalog/sfg/a3207'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a3207}
              title={'Сумка-органайзер'}
              titlepoi={'Фото'}
              imagespoi={a3207poi}


            />
          }
        />

        <Route
          path='/catalog/sfg/a3208'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a3208}
              title={'Сумка-органайзер L'}
              titlepoi={'Фото'}
              imagespoi={a3208poi}


            />
          }
        />

        <Route
          path='/catalog/sfg/a3209'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a3209}
              title={'Рюкзак'}
              titlepoi={'Фото'}
              imagespoi={a3209poi}


            />
          }
        />

        <Route
          path='/catalog/sfg/a3220'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a3220}
              title={'Контейнер складной, разборный 20л'}
              titlepoi={'Фото'}
              imagespoi={a3220poi}


            />
          }
        />

        <Route
          path='/catalog/sfg/a3221'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a3221}
              title={'Столешница для контейнера 20л'}
              titlepoi={'Фото'}
              imagespoi={a3221poi}


            />
          }
        />

        <Route
          path='/catalog/sfg/a3222'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a3222}
              title={'Контейнер складной, разборный 32л'}
              titlepoi={'Фото'}
              imagespoi={a3222poi}


            />
          }
        />

        <Route
          path='/catalog/sfg/a3223'
          element={
            <Catalog
              catalogList={catalogList}
              catalogItem={a3223}
              title={'Столешница для контейнера 32л'}
              titlepoi={'Фото'}
              imagespoi={a3223poi}


            />
          }
        />







      </Routes>


      {/* <Footer /> */}



    </div>
  );
}

export default App;
