export const A3202 =
    [
        {
            id: 1,
            code: "A-3202-11",
            name: "Папка-сумка L-образная",
            link: "https://lihitlab-jp.ru/images/A3202/A-3202_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222515500/detail.aspx",
        },

        {
            id: 1,
            code: "A-3202-16",
            name: "Папка-сумка L-образная",
            link: "https://lihitlab-jp.ru/images/A3202/A-3202_16.jpg",
            color: "Серый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222515501/detail.aspx",
        },

        {
            id: 1,
            code: "A-3202-24",
            name: "Папка-сумка L-образная",
            link: "https://lihitlab-jp.ru/images/A3202/A-3202_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222515502/detail.aspx",
        },
        
        
       
    ]

export const A3202POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3202/A-3202_poi1.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3202/A-3202_poi2.jpg",
        },

        
      

    ]