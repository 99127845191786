export const A6005 =
    [
        {
            id: 1,
            code: "A-6005-5",
            name: "Папка-сумка А4",
            link: "https://lihitlab-jp.ru/images/A6005/A-6005-5.jpg",
            color: "Желттый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/48846032/detail.aspx",
        },
        {
            id: 2,
            code: "A-6005-7",
            name: "Папка-сумка А4",
            link: "https://lihitlab-jp.ru/images/A6005/A-6005-7.jpg",
            color: "Зеленый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/48846033/detail.aspx",
        },
        {
            id: 3,
            code: "A-6005-8",
            name: "Папка-сумка А4",
            link: "https://lihitlab-jp.ru/images/A6005/A-6005-8.jpg",
            color: "Синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/48846034/detail.aspx",
        },
        {
            id: 5,
            code: "A-6005-11",
            name: "Папка-сумка А4",
            link: "https://lihitlab-jp.ru/images/A6005/A-6005-11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/48846036/detail.aspx",
        },
        {
            id: 6,
            code: "A-6005-12",
            name: "Папка-сумка А4",
            link: "https://lihitlab-jp.ru/images/A6005/A-6005-12.jpg",
            color: "Розовый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/48846037/detail.aspx",
        },
        {
            id: 7,
            code: "A-6005-16",
            name: "Папка-сумка А4",
            link: "https://lihitlab-jp.ru/images/A6005/A-6005-16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: false,
            linkWB:"https://www.wildberries.ru/catalog/48846038/detail.aspx",
        },
        
       
    ]

export const A6005POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A6005/A-6005_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A6005/A-6005_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A6005/A-6005_poi3.jpg",
        },
     
            

    ]