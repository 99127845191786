export const A3209 =
    [
        {
            id: 1,
            code: "A-3209-24",
            name: "Рюкзак",
            link: "https://lihitlab-jp.ru/images/A3209/A-3209_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/225413835/detail.aspx",
        },     
        
       
    ]

export const A3209POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3209/A-3209_poi1.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3209/A-3209_poi2.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3209/A-3209_poi3.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3209/A-3209_poi4.jpg",
        },

        
      

    ]