import './Reviews.css';



function Reviews() {
  

  return (
    <section className='reviews-container'>


        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-20-0.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7559-24.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7574-9.jpg" alt="A-20-0"/>


        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7577-24.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7583-24.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7651-24.jpg" alt="A-20-0"/>


        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7670-11.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7680-11.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7688-6.jpg" alt="A-20-0"/>


        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7712-1.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7712-2.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7712-3.jpg" alt="A-20-0"/>


        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7712-4.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7712-6.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7712-7.jpg" alt="A-20-0"/>


        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7712-13.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7720-3.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7731-11.jpg" alt="A-20-0"/>

        
        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7732-19.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7737-19.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7750-24.jpg" alt="A-20-0"/>

        
        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7759-24.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7760-24.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7773-24.jpg" alt="A-20-0"/>


        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7781-18.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7783-4.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7784-4.jpg" alt="A-20-0"/>


        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7791-22.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7803-7.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7804-7.jpg" alt="A-20-0"/>


        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7901-5.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7912-11.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7940-24.jpg" alt="A-20-0"/>


        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_A-7942-19.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_F-7737-10.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_G-1641-3.jpg" alt="A-20-0"/>


        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_N-1670-0.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_N-1908-16.jpg" alt="A-20-0"/>

        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_N-2993.jpg" alt="A-20-0"/>


        <img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/Reviews/review_N-3103.jpg" alt="A-20-0"/>





    </section>
  );
}

export default Reviews;
