export const A2221 =
    [
        {
            id: 1,
            code: "A-2221-7",
            name: "Пенал-плоский",
            link: "https://lihitlab-jp.ru/images/A2221/A-2221_7.jpg",
            color: "Фисташковый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222267845/detail.aspx",
        },
        {
            id: 2,
            code: "A-2221-11",
            name: "Пенал-плоский",
            link: "https://lihitlab-jp.ru/images/A2221/A-2221_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222267846/detail.aspx",
        },
        {
            id: 3,
            code: "A-2221-16",
            name: "Пенал-плоский",
            link: "https://lihitlab-jp.ru/images/A2221/A-2221_16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222267847/detail.aspx",
        },
        {
            id: 4,
            code: "A-2221-24",
            name: "Пенал-плоский",
            link: "https://lihitlab-jp.ru/images/A2221/A-2221_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222267848/detail.aspx",
        },    
        
       
    ]

export const A2221POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A2221/A-2221_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A2221/A-2221_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A2221/A-2221_poi3.jpg",
        },
      
      

    ]