import './Navigation.css';

import { Link } from 'react-router-dom';

function Navigation() {
  return (
   

      <nav className="nav">
        <ul className="nav__list">
          <li className="nav__list-item"><Link to="/" className="nav__list-link">Главная</Link></li>
          <li className="nav__list-item"><Link to="/catalog" className="nav__list-link">Каталог</Link></li>
          <li className="nav__list-item"><Link to="/brend" className="nav__list-link">О бренде</Link></li>
          <li className="nav__list-item"><Link to="/reviews" className="nav__list-link">Отзывы</Link></li>
          <li className="nav__list-item"><Link to="/gallery" className="nav__list-link">Галерея</Link></li>
          <li className="nav__list-item"><Link to="/contacts" className="nav__list-link">Контакты</Link></li>

        </ul>


      </nav>    
     
      
    
  );
}

export default Navigation;
