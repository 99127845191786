import './Gallery.css';



function Gallery() {
  

  return (
    <section className='gallery-container'>

<img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/gallery/gallery1.jpg" alt=" "/>
<img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/gallery/Gallery2.jpg" alt=" "/>
<img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/gallery/Gallery3.jpg" alt=" "/>
<img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/gallery/Gallery4.jpg" alt=" "/>

<img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/gallery/Gallery6.jpg" alt=" "/>
<img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/gallery/Gallery8.jpg" alt=" "/>
<img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/gallery/Gallery9.jpg" alt=" "/>

<img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/gallery/Gallery12.jpg" alt=" "/>
<img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/gallery/Gallery13.jpg" alt=" "/>
<img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/gallery/Gallery14.jpg" alt=" "/>
<img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/gallery/Gallery15.jpg" alt=" "/>

<img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/gallery/Gallery16.jpg" alt=" "/>
<img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/gallery/Gallery17.jpg" alt=" "/>
<img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/gallery/Gallery18.jpg" alt=" "/>
<img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/gallery/Gallery19.jpg" alt=" "/>
<img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/gallery/Gallery20.jpg" alt=" "/>

<img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/gallery/Gallery21.jpg" alt=" "/>
<img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/gallery/Gallery22.png" alt=" "/>
<img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/gallery/Gallery23.png" alt=" "/>
<img className="catalog__imgpoi" src="https://lihitlab-jp.ru//images/gallery/Gallery24.JPG" alt=" "/>









    </section>
  );
}

export default Gallery;
