export const A3203 =
    [
        {
            id: 1,
            code: "A-3203-11",
            name: "Кошелек-ключница на карабине",
            link: "https://lihitlab-jp.ru/images/A3203/A-3203_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/224058237/detail.aspx",
        },

        {
            id: 1,
            code: "A-3203-14",
            name: "Кошелек-ключница на карабине",
            link: "https://lihitlab-jp.ru/images/A3203/A-3203_14.jpg",
            color: "Голубой",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/224058238/detail.aspx",
        },

        {
            id: 1,
            code: "A-3203-16",
            name: "Кошелек-ключница на карабине",
            link: "https://lihitlab-jp.ru/images/A3203/A-3203_16.jpg",
            color: "Серый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/224058239/detail.aspx",
        },

        {
            id: 1,
            code: "A-3203-22",
            name: "Кошелек-ключница на карабине",
            link: "https://lihitlab-jp.ru/images/A3203/A-3203_22.jpg",
            color: "Зеленый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/224058240/detail.aspx",
        },

        {
            id: 1,
            code: "A-3203-24",
            name: "Кошелек-ключница на карабине",
            link: "https://lihitlab-jp.ru/images/A3203/A-3203_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/224058241/detail.aspx",
        },
        
        
       
    ]

export const A3203POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3203/A-3203_poi1.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3203/A-3203_poi2.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3203/A-3203_poi3.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3203/A-3203_poi4.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3203/A-3203_poi5.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3203/A-3203_poi6.jpg",
        },

        
      

    ]