export const CARDS = 
[

    // -----------------------------PUNILABO------------------------
    {
        id: 1,
        code: "A-7712",
        name: "Пенал-тубус малый",
        link: "https://lihitlab-jp.ru/images/A7712/A-7712.jpg",
        cardsRoute: "/catalog/punilabo/a7712",
        bestseller: true,
        new: false,
    },
    {
        id: 2,
        code: "A-7713",
        name: "Держатель для смартфона",
        link: "https://lihitlab-jp.ru/images/A7713/A-7713.jpg",
        cardsRoute: "/catalog/punilabo/a7713",
        bestseller: true,
        new: false,
    },
    {
        id: 3,
        code: "A-7714",
        name: "Пенал-тубус большой",
        link: "https://lihitlab-jp.ru/images/A7714/A-7714.jpg",
        cardsRoute: "/catalog/punilabo/a7714",
        bestseller: true,
        new: false,
    },
    {
      id: 4,
      code: "A-7716",
      name: "Пенал-косметичка",
      link: "https://lihitlab-jp.ru/images/A7716/A-7716.jpg",
      cardsRoute: "/catalog/punilabo/a7716",
      bestseller: true,
      new: false,
  },
  {
      id: 5,
      code: "A-7717",
      name: "Пенал узкий, длинный",
      link: "https://lihitlab-jp.ru/images/A7717/A-7717.jpg",
      cardsRoute: "/catalog/punilabo/a7717",
      bestseller: true,
      new: false,
  },
  
  {
      id: 6,
      code: "A-7718",
      name: "Брелок с звуковой сигнализацией",
      link: "https://lihitlab-jp.ru/images/A7718/A-7718.jpg",
      cardsRoute: "/catalog/punilabo/a7718",
      bestseller: true,
      new: false,
  },
  {
    id: 7,
    code: "A-7720",
    name: "Пенал с ремешком",
    link: "https://lihitlab-jp.ru/images/A7720/A-7720.jpg",
    cardsRoute: "/catalog/punilabo/a7720",
    bestseller: true,
    new: false,
  },
  {
    id: 8,
    code: "A-7721",
    name: "Магнитная клипса",
    link: "https://lihitlab-jp.ru/images/A7721/A-7721.jpg",
    cardsRoute: "/catalog/punilabo/a7721",
    bestseller: false,
    new: false,
  },
  {
    id: 9,
    code: "A-7722",
    name: "Стягиватель",
    link: "https://lihitlab-jp.ru/images/A7722/A-7722.jpg",
    cardsRoute: "/catalog/punilabo/a7722",
    bestseller: false,
    new: false,
  },
  {
    id: 10,
    code: "A-7723",
    name: "Кейс для записей",
    link: "https://lihitlab-jp.ru/images/A7723/A-7723.jpg",
    cardsRoute: "/catalog/punilabo/a7723",
    bestseller: false,
    new: false,
  },
  {
    id: 11,
    code: "A-7724",
    name: "Кейс для записей, большой",
    link: "https://lihitlab-jp.ru/images/A7724/A-7724.jpg",
    cardsRoute: "/catalog/punilabo/a7724",
    bestseller: false,
    new: false,
  },
  {
    id: 12,
    code: "A-7725",
    name: "Магнит канцелярский",
    link: "https://lihitlab-jp.ru/images/A7725/A-7725.jpg",
    cardsRoute: "/catalog/punilabo/a7725",
    bestseller: false,
    new: false,
  },
  {
    id: 13,
    code: "A-7727",
    name: "Держатель для кабеля телефона",
    link: "https://lihitlab-jp.ru/images/A7727/A-7727.jpg",
    cardsRoute: "/catalog/punilabo/a7727",
    bestseller: false,
    new: false,
  },
  {
    id: 14,
    code: "A-7728",
    name: "Очиститель экрана",
    link: "https://lihitlab-jp.ru/images/A7728/A-7728.jpg",
    cardsRoute: "/catalog/punilabo/a7728",
    bestseller: false,
    new: false,
  },
  {
    id: 15,
    code: "A-7780",
    name: "Пенал лежащий, мини",
    link: "https://lihitlab-jp.ru/images/A7780/A-7780.jpg",
    cardsRoute: "/catalog/punilabo/a7780",
    bestseller: true,
    new: false,
  },
  {
    id: 16,
    code: "A-7781",
    name: "Пенал лежащий, длинный",
    link: "https://lihitlab-jp.ru/images/A7781/A-7781.jpg",
    cardsRoute: "/catalog/punilabo/a7781",
    bestseller: true,
    new: false,
  },
  {
    id: 17,
    code: "A-7782",
    name: "Пенал яйцо",
    link: "https://lihitlab-jp.ru/images/A7782/A-7782.jpg",
    cardsRoute: "/catalog/punilabo/a7782",
    bestseller: true,
    new: false,
  },
  {
    id: 18,
    code: "A-7783",
    name: "Пенал яйцо, большой",
    link: "https://lihitlab-jp.ru/images/A7783/A-7783.jpg",
    cardsRoute: "/catalog/punilabo/a7783",
    bestseller: true,
    new: false,
  },
  {
    id: 19,
    code: "A-7784",
    name: "Кошелек",
    link: "https://lihitlab-jp.ru/images/A7784/A-7784.jpg",
    cardsRoute: "/catalog/punilabo/a7784",
    bestseller: true,
    new: false,
  },
  {
    id: 20,
    code: "A-7785",
    name: "Ключница",
    link: "https://lihitlab-jp.ru/images/A7785/A-7785.jpg",
    cardsRoute: "/catalog/punilabo/a7785",
    bestseller: true,
    new: false,
  },
  {
    id: 21,
    code: "A-7800",
    name: "Пенал лежащий, большой",
    link: "https://lihitlab-jp.ru/images/A7800/A-7800.jpg",
    cardsRoute: "/catalog/punilabo/a7800",
    bestseller: true,
    new: false,
  },
  {
    id: 22,
    code: "A-7803",
    name: "Ножницы",
    link: "https://lihitlab-jp.ru/images/A7803/A-7803.jpg",
    cardsRoute: "/catalog/punilabo/a7803",
    bestseller: true,
    new: false,
  },
  {
    id: 23,
    code: "A-7804",
    name: "Бейджик",
    link: "https://lihitlab-jp.ru/images/A7804/A-7804.jpg",
    cardsRoute: "/catalog/punilabo/a7804",
    bestseller: true,
    new: false,
  },



  // -----------------------------ALTNA------------------------





  {
    id: 24,
    code: "A-7760",
    name: "Бизнес сумка",
    link: "https://lihitlab-jp.ru/images/A7760/A-7760.jpg",
    cardsRoute: "/catalog/altna/a7760",
    bestseller: true,
    new: false,
  },
  {
    id: 25,
    code: "A-7750",
    name: "Сумка-органайзер горизонтальная",
    link: "https://lihitlab-jp.ru/images/A7750/A-7750.jpg",
    cardsRoute: "/catalog/altna/a7750",
    bestseller: false,
    new: true,
  },
  {
    id: 26,
    code: "A-7751",
    name: "Сумка-органайзер вертикальная",
    link: "https://lihitlab-jp.ru/images/A7751/A-7751.jpg",
    cardsRoute: "/catalog/altna/a7751",
    bestseller: false,
    new: true,
  },
  {
    id: 27,
    code: "A-7752",
    name: "Сумка-органайзер",
    link: "https://lihitlab-jp.ru/images/A7752/A-7752.jpg",
    cardsRoute: "/catalog/altna/a7752",
    bestseller: false,
    new: true,
  },
  {
    id: 28,
    code: "A-7773",
    name: "Папка-сумка складная",
    link: "https://lihitlab-jp.ru/images/A7773/A-7773.jpg",
    cardsRoute: "/catalog/altna/a7773",
    bestseller: false,
    new: false,
  },
  {
    id: 29,
    code: "A-7791",
    name: "Крючек для сумки",
    link: "https://lihitlab-jp.ru/images/A7791/A-7791.jpg",
    cardsRoute: "/catalog/altna/a7791",
    bestseller: false,
    new: false,
  },
  {
    id: 30,
    code: "А-7767",
    name: "Папка-сумка органайзер M",
    link: "https://lihitlab-jp.ru/images/A7767/A-7767.jpg",
    cardsRoute: "/catalog/altna/a7767",
    bestseller: false,
    new: false,
  },
  {
    id: 31,
    code: "А-7768",
    name: "Папка-сумка органайзер L",
    link: "https://lihitlab-jp.ru/images/A7768/A-7768.jpg",
    cardsRoute: "/catalog/altna/a7768",
    bestseller: false,
    new: false,
  },
  {
    id: 31,
    code: "А-7759",
    name: "Пенал-тубус с магнитной крышкой",
    link: "https://lihitlab-jp.ru/images/A7759/A-7759.jpg",
    cardsRoute: "/catalog/altna/a7759",
    bestseller: false,
    new: false,
  },
  {
    id: 32,
    code: "А-7790",
    name: "Держатель смартфона",
    link: "https://lihitlab-jp.ru/images/A7790/A-7790.jpg",
    cardsRoute: "/catalog/altna/a7790",
    bestseller: false,
    new: false,
  },
  {
    id: 33,
    code: "А-7764",
    name: "Пенал-органайзер",
    link: "https://lihitlab-jp.ru/images/A7764/A-7764.jpg",
    cardsRoute: "/catalog/altna/a7764",
    bestseller: false,
    new: false,
  },
  {
    id: 34,
    code: "А-7765",
    name: "Пенал-органайзер большой",
    link: "https://lihitlab-jp.ru/images/A7765/A-7765.jpg",
    cardsRoute: "/catalog/altna/a7765",
    bestseller: false,
    new: false,
  },


  // -----------------------------SMART FIT------------------------


  {
    id: 29,
    code: "A-8800",
    name: "Папка-сумка складная",
    link: "https://lihitlab-jp.ru/images/A8800/A-8800.jpg",
    cardsRoute: "/catalog/smartfit/a8800",
    bestseller: false,
    new: true,
},
{
    id: 30,
    code: "A-7701",
    name: "Клатч",
    link: "https://lihitlab-jp.ru/images/A7701/A-7701.jpg",
    cardsRoute: "/catalog/smartfit/a7701",
    bestseller: false,
    new: false,
},
{
    id: 31,
    code: "A-7702",
    name: "Клатч большой",
    link: "https://lihitlab-jp.ru/images/A7702/A-7702.jpg",
    cardsRoute: "/catalog/smartfit/a7702",
    bestseller: false,
    new: false,
},
{
    id: 32,
    code: "A-7574",
    name: "Сумка поясная",
    link: "https://lihitlab-jp.ru/images/A7574/A-7574.jpg",
    cardsRoute: "/catalog/smartfit/a7574",
    bestseller: false,
    new: false,
},
{
    id: 33,
    code: "A-7575",
    name: "Папка-сумка для гаджетов А5",
    link: "https://lihitlab-jp.ru/images/A7575/A-7575.jpg",
    cardsRoute: "/catalog/smartfit/a7575",
    bestseller: false,
    new: false,
},
{
    id: 34,
    code: "A-7576",
    name: "Папка-сумка для гаджетов В5",
    link: "https://lihitlab-jp.ru/images/A7576/A-7576.jpg",
    cardsRoute: "/catalog/smartfit/a7576",
    bestseller: false,
    new: false,
},
{
    id: 35,
    code: "A-7577",
    name: "Папка-сумка для гаджетов А4",
    link: "https://lihitlab-jp.ru/images/A7577/A-7577.jpg",
    cardsRoute: "/catalog/smartfit/a7577",
    bestseller: false,
    new: false,
},
{
    id: 36,
    code: "N-1627",
    name: "Блокнот-органайзер B5",
    link: "https://lihitlab-jp.ru/images/N1627/N-1627.jpg",
    cardsRoute: "/catalog/smartfit/n1627",
    bestseller: false,
    new: false,
},
{
    id: 37,
    code: "N-1647",
    name: "Блокнот-органайзер A5",
    link: "https://lihitlab-jp.ru/images/N1647/N-1647.jpg",
    cardsRoute: "/catalog/smartfit/n1647",
    bestseller: false,
    new: false,
},
{
    id: 38,
    code: "A-7585",
    name: "Пенал-портмоне",
    link: "https://lihitlab-jp.ru/images/A7585/A-7585.jpg",
    cardsRoute: "/catalog/smartfit/a7585",
    bestseller: false,
    new: false,
  },
  {
    id: 39,
    code: "A-7581",
    name: "Сумка c увеличивающейся толщиной B4",
    link: "https://lihitlab-jp.ru/images/A7581/A-7581.jpg",
    cardsRoute: "/catalog/smartfit/a7581",
    bestseller: false,
    new: false,
  },
  {
    id: 41,
    code: "A-7582",
    name: "Сумка c увеличивающейся толщиной B4",
    link: "https://lihitlab-jp.ru/images/A7582/A-7582.jpg",
    cardsRoute: "/catalog/smartfit/a7582",
    bestseller: false,
    new: false,
  },
  {
    id: 42,
    code: "A-7583",
    name: "Сумка-шоппер B4/A4",
    link: "https://lihitlab-jp.ru/images/A7583/A-7583.jpg",
    cardsRoute: "/catalog/smartfit/a7583",
    bestseller: false,
    new: false,
  },
  {
    id: 43,
    code: "A-7595",
    name: "Крючек для сумки",
    link: "https://lihitlab-jp.ru/images/A7595/A-7595.jpg",
    cardsRoute: "/catalog/smartfit/a7595",
    bestseller: false,
    new: false,
  },
  {
    id: 44,
    code: "A-7650",
    name: "Сумка B4 c удлиняющейся ручкой",
    link: "https://lihitlab-jp.ru/images/A7650/A-7650.jpg",
    cardsRoute: "/catalog/smartfit/a7650",
    bestseller: false,
    new: false,
  },
  {
    id: 44,
    code: "A-7651",
    name: "Сумка B4 c удлиняющейся ручкой широкая",
    link: "https://lihitlab-jp.ru/images/A7651/A-7651.jpg",
    cardsRoute: "/catalog/smartfit/a7651",
    bestseller: false,
    new: false,
  },
  {
    id: 45,
    code: "A-7554",
    name: "Папка-сумка органайзер А4",
    link: "https://lihitlab-jp.ru/images/A7554/A-7554.jpg",
    cardsRoute: "/catalog/smartfit/a7554",
    bestseller: false,
    new: false,
  },
  {
    id: 46,
    code: "A-7558",
    name: "Папка-сумка органайзер А5",
    link: "https://lihitlab-jp.ru/images/A7558/A-7558.jpg",
    cardsRoute: "/catalog/smartfit/a7558",
    bestseller: false,
    new: false,
  },
  {
    id: 47,
    code: "A-7559",
    name: "Папка-сумка органайзер А4",
    link: "https://lihitlab-jp.ru/images/A7559/A-7559.jpg",
    cardsRoute: "/catalog/smartfit/a7559",
    bestseller: false,
    new: false,
  },
  {
    id: 48,
    code: "A-7551",
    name: "Пенал-органайзер с одним отделом",
    link: "https://lihitlab-jp.ru/images/A7551/A-7551.jpg",
    cardsRoute: "/catalog/smartfit/a7551",
    bestseller: false,
    new: false,
  },
  {
    id: 49,
    code: "A-7552",
    name: "Пенал-органайзер с одним отделом узкий",
    link: "https://lihitlab-jp.ru/images/A7552/A-7552.jpg",
    cardsRoute: "/catalog/smartfit/a7552",
    bestseller: false,
    new: false,
  },
  {
    id: 50,
    code: "A-7555",
    name: "Пенал-органайзер с двумя отделами",
    link: "https://lihitlab-jp.ru/images/A7555/A-7555.jpg",
    cardsRoute: "/catalog/smartfit/a7555",
    bestseller: false,
    new: false,
  },
  {
    id: 51,
    code: "A-7556",
    name: "Пенал-органайзер с тремя отделами",
    link: "https://lihitlab-jp.ru/images/A7556/A-7556.jpg",
    cardsRoute: "/catalog/smartfit/a7556",
    bestseller: false,
    new: false,
  },
  {
    id: 52,
    code: "A-7557",
    name: "Пенал-органайзер с двумя отделами узкий",
    link: "https://lihitlab-jp.ru/images/A7557/A-7557.jpg",
    cardsRoute: "/catalog/smartfit/a7557",
    bestseller: false,
    new: false,
  },
  {
    id: 53,
    code: "A-7652",
    name: "Пенал плоский A-7652",
    link: "https://lihitlab-jp.ru/images/A7652/A-7652.jpg",
    cardsRoute: "/catalog/smartfit/a7652",
    bestseller: false,
    new: false,
  },
  {
    id: 54,
    code: "A-7653",
    name: "Пенал плоский большой A-7653",
    link: "https://lihitlab-jp.ru/images/A7653/A-7653.jpg",
    cardsRoute: "/catalog/smartfit/a7653",
    bestseller: false,
    new: false,
  },

   // -----------------------------ACTACT------------------------

   {
    id: 55,
    code: "А-7694",
    name: "Пенал-тубус овальный",
    link: "https://lihitlab-jp.ru/images/A7694/A-7694.jpg",
    cardsRoute: "/catalog/actact/a7694",
    bestseller: false,
    new: false,
  },
  {
    id: 56,
    code: "А-7695",
    name: "Пенал-тубус овальный большой",
    link: "https://lihitlab-jp.ru/images/A7695/A-7695.jpg",
    cardsRoute: "/catalog/actact/a7695",
    bestseller: false,
    new: false,
  },
  {
    id: 57,
    code: "А-7670",
    name: "Сумка-рюкзак горизонтальная",
    link: "https://lihitlab-jp.ru/images/A7670/A-7670.jpg",
    cardsRoute: "/catalog/actact/a7670",
    bestseller: false,
    new: false,
  },
  {
    id: 58,
    code: "А-7672",
    name: "Сумка-рюкзак вертикальная",
    link: "https://lihitlab-jp.ru/images/A7672/A-7672.jpg",
    cardsRoute: "/catalog/actact/a7672",
    bestseller: false,
    new: false,
  },
  {
    id: 59,
    code: "А-7680",
    name: "Папка-сумка органайзер горизонтальная А5",
    link: "https://lihitlab-jp.ru/images/A7680/A-7680.jpg",
    cardsRoute: "/catalog/actact/a7680",
    bestseller: false,
    new: false,
  },
  {
    id: 60,
    code: "А-7681",
    name: "Папка-сумка органайзер горизонтальная А4",
    link: "https://lihitlab-jp.ru/images/A7681/A-7681.jpg",
    cardsRoute: "/catalog/actact/a7681",
    bestseller: false,
    new: false,
  },
  {
    id: 61,
    code: "А-7682",
    name: "Папка-сумка органайзер вертикальная А5",
    link: "https://lihitlab-jp.ru/images/A7682/A-7682.jpg",
    cardsRoute: "/catalog/actact/a7682",
    bestseller: false,
    new: false,
  },
  {
    id: 62,
    code: "А-7683",
    name: "Папка-сумка органайзер вертикальная А4",
    link: "https://lihitlab-jp.ru/images/A7683/A-7683.jpg",
    cardsRoute: "/catalog/actact/a7683",
    bestseller: false,
    new: false,
  },
  {
    id: 63,
    code: "А-7686",
    name: "Пенал-органайзер дорожный",
    link: "https://lihitlab-jp.ru/images/A7686/A-7686.jpg",
    cardsRoute: "/catalog/actact/a7686",
    bestseller: false,
    new: false,
  },
  {
    id: 64,
    code: "А-7687",
    name: "Пенал-органайзер плоский",
    link: "https://lihitlab-jp.ru/images/A7687/A-7687.jpg",
    cardsRoute: "/catalog/actact/a7687",
    bestseller: false,
    new: false,
  },
  {
    id: 65,
    code: "А-7688",
    name: "Пенал-косметичка",
    link: "https://lihitlab-jp.ru/images/A7688/A-7688.jpg",
    cardsRoute: "/catalog/actact/a7688",
    bestseller: false,
    new: false,
  },

  // -----------------------------HINEMO------------------------

  {
    id: 65,
    code: "А-7900",
    name: "Пенал-косметичка M",
    link: "https://lihitlab-jp.ru/images/A7900/A-7900.jpg",
    cardsRoute: "/catalog/hinemo/a7900",
    bestseller: false,
    new: true,
  },
  {
    id: 66,
    code: "А-7901",
    name: "Пенал-косметичка L",
    link: "https://lihitlab-jp.ru/images/A7901/A-7901.jpg",
    cardsRoute: "/catalog/hinemo/a7901",
    bestseller: false,
    new: true,
  },
  {
    id: 67,
    code: "А-7902",
    name: "Пенал двухсторонний L-образный",
    link: "https://lihitlab-jp.ru/images/A7902/A-7902.jpg",
    cardsRoute: "/catalog/hinemo/a7902",
    bestseller: false,
    new: true,
  },
  {
    id: 68,
    code: "А-7910",
    name: "Папка-сумка двухсторонняя S",
    link: "https://lihitlab-jp.ru/images/A7910/A-7910.jpg",
    cardsRoute: "/catalog/hinemo/a7910",
    bestseller: false,
    new: true,
  },
  {
    id: 69,
    code: "А-7911",
    name: "Папка-сумка двухсторонняя M",
    link: "https://lihitlab-jp.ru/images/A7911/A-7911.jpg",
    cardsRoute: "/catalog/hinemo/a7911",
    bestseller: false,
    new: true,
  },
  {
    id: 70,
    code: "А-7912",
    name: "Папка-сумка двухсторонняя L",
    link: "https://lihitlab-jp.ru/images/A7912/A-7912.jpg",
    cardsRoute: "/catalog/hinemo/a7912",
    bestseller: false,
    new: true,
  },

   // -----------------------------BLOOMIN------------------------

   {
    id: 71,
    code: "А-7730",
    name: "Пенал футляр",
    link: "https://lihitlab-jp.ru/images/A7730/A-7730.jpg",
    cardsRoute: "/catalog/bloomin/a7730",
    bestseller: false,
    new: false,
  },
  {
    id: 72,
    code: "А-7731",
    name: "Пенал футляр большой",
    link: "https://lihitlab-jp.ru/images/A7731/A-7731.jpg",
    cardsRoute: "/catalog/bloomin/a7731",
    bestseller: false,
    new: false,
  },
  {
    id: 73,
    code: "А-7732",
    name: "Пенал-тубус",
    link: "https://lihitlab-jp.ru/images/A7732/A-7732.jpg",
    cardsRoute: "/catalog/bloomin/a7732",
    bestseller: false,
    new: false,
  },
  {
    id: 74,
    code: "А-7733",
    name: "Пенал с ремешком",
    link: "https://lihitlab-jp.ru/images/A7733/A-7733.jpg",
    cardsRoute: "/catalog/bloomin/a7733",
    bestseller: false,
    new: false,
  },
  {
    id: 75,
    code: "А-7736",
    name: "Косметичка",
    link: "https://lihitlab-jp.ru/images/A7736/A-7736.jpg",
    cardsRoute: "/catalog/bloomin/a7736",
    bestseller: false,
    new: false,
  },
  {
    id: 76,
    code: "А-7737",
    name: "Сумочка-косметичка",
    link: "https://lihitlab-jp.ru/images/A7737/A-7737.jpg",
    cardsRoute: "/catalog/bloomin/a7737",
    bestseller: false,
    new: false,
  },
  {
    id: 77,
    code: "А-7739",
    name: "Папка для документов А4",
    link: "https://lihitlab-jp.ru/images/A7739/A-7739.jpg",
    cardsRoute: "/catalog/bloomin/a7739",
    bestseller: false,
    new: false,
  },
  {
    id: 78,
    code: "F-7737",
    name: "Кошелек",
    link: "https://lihitlab-jp.ru/images/F7737/F-7737.jpg",
    cardsRoute: "/catalog/bloomin/f7737",
    bestseller: false,
    new: false,
  },
  {
    id: 79,
    code: "F-7738",
    name: "Пенал плоский",
    link: "https://lihitlab-jp.ru/images/F7738/F-7738.jpg",
    cardsRoute: "/catalog/bloomin/f7738",
    bestseller: false,
    new: false,
  },
  {
    id: 80,
    code: "F-7739",
    name: "Папка",
    link: "https://lihitlab-jp.ru/images/F7739/F-7739.jpg",
    cardsRoute: "/catalog/bloomin/f7739",
    bestseller: false,
    new: false,
  },


  // -----------------------------ALCLEA------------------------


  {
    id: 100,
    code: "A-7940",
    name: "Планшет с антибактериальным покрытием с держателем для ручки",
    link: "https://lihitlab-jp.ru/images/A7940/A-7940.jpg",
    cardsRoute: "/catalog/alclea/a7940",
    bestseller: false,
    new: false,
  },
  {
      id: 101,
      code: "F-7740",
      name: "Папка-планшет с зажимом А4",
      link: "https://lihitlab-jp.ru/images/F7740/F-7740.jpg",
      cardsRoute: "/catalog/alclea/f7740",
      bestseller: false,
      new: false,
    },
    {
      id: 102,
      code: "A-7941",
      name: "Пенал раскладной, с прозрачным карманом",
      link: "https://lihitlab-jp.ru/images/A7941/A-7941.jpg",
      cardsRoute: "/catalog/alclea/a7941",
      bestseller: false,
      new: false,
    },
    {
      id: 103,
      code: "A-7942",
      name: "Пенал-косметичка с прозрачными карманами",
      link: "https://lihitlab-jp.ru/images/A7942/A-7942.jpg",
      cardsRoute: "/catalog/alclea/a7942",
      bestseller: false,
      new: false,
    },
    {
      id: 104,
      code: "HM-511",
      name: "Сумочка для лекарств и пренадлежностей S",
      link: "https://lihitlab-jp.ru/images/HM511/HM511.jpg",
      cardsRoute: "/catalog/alclea/hm511",
      bestseller: false,
      new: false,
    },
    {
      id: 105,
      code: "HM-521",
      name: "Сумочка для документов А6",
      link: "https://lihitlab-jp.ru/images/HM521/HM521.jpg",
      cardsRoute: "/catalog/alclea/hm521",
      bestseller: false,
      new: false,
    },

// -----------------------------NYAN-SCENE------------------------
    {
      id: 24,
      code: "A-2220",
      name: "Кошелек",
      link: "https://lihitlab-jp.ru/images/A2220/A-2220.jpg",
      cardsRoute: "/catalog/nyan-scene/a2220",
      bestseller: false,
      new: true,
    },
    {
      id: 25,
      code: "A-2221",
      name: "Пенал-плоский",
      link: "https://lihitlab-jp.ru/images/A2221/A-2221.jpg",
      cardsRoute: "/catalog/nyan-scene/a2221",
      bestseller: false,
      new: true,
    },
    {
      id: 26,
      code: "A-2222",
      name: "Клатч плоский",
      link: "https://lihitlab-jp.ru/images/A2222/A-2222.jpg",
      cardsRoute: "/catalog/nyan-scene/a2222",
      bestseller: false,
      new: true,
    },

    {
      id: 27,
      code: "A-2223",
      name: "Пенал широкий",
      link: "https://lihitlab-jp.ru/images/A2223/A-2223.jpg",
      cardsRoute: "/catalog/nyan-scene/a2223",
      bestseller: false,
      new: true,
    },

    {
      id: 28,
      code: "A-2224",
      name: "Пенал-косметичка",
      link: "https://lihitlab-jp.ru/images/A2224/A-2224.jpg",
      cardsRoute: "/catalog/nyan-scene/a2224",
      bestseller: false,
      new: true,
    },

    {
      id: 29,
      code: "A-2225",
      name: "Сумочка кошелек с ремешком",
      link: "https://lihitlab-jp.ru/images/A2225/A-2225.jpg",
      cardsRoute: "/catalog/nyan-scene/a2225",
      bestseller: false,
      new: true,
    },

    {
      id: 30,
      code: "A-2226",
      name: "Сумка-органайзер для ноутбука",
      link: "https://lihitlab-jp.ru/images/A2226/A-2226.jpg",
      cardsRoute: "/catalog/nyan-scene/a2226",
      bestseller: false,
      new: true,
    },

    // -----------------------------SFG------------------------

    {
      id: 130,
      code: "A-3200",
      name: "Пенал-органайзер",
      link: "https://lihitlab-jp.ru/images/A3200/A-3200.jpg",
      cardsRoute: "/catalog/sfg/a3200",
      bestseller: false,
      new: true,
    },

    {
      id: 131,
      code: "A-3201",
      name: "Пенал органайзер L-образный",
      link: "https://lihitlab-jp.ru/images/A3201/A-3201.jpg",
      cardsRoute: "/catalog/sfg/a3201",
      bestseller: false,
      new: true,
    },

    {
      id: 131,
      code: "A-3202",
      name: "Папка-сумка L-образная",
      link: "https://lihitlab-jp.ru/images/A3202/A-3202.jpg",
      cardsRoute: "/catalog/sfg/a3202",
      bestseller: false,
      new: true,
    },

    {
      id: 131,
      code: "A-3203",
      name: "Кошелек-ключница на карабине",
      link: "https://lihitlab-jp.ru/images/A3203/A-3203.jpg",
      cardsRoute: "/catalog/sfg/a3203",
      bestseller: false,
      new: true,
    },

    {
      id: 131,
      code: "A-3204",
      name: "Сумочка поясная для телефона",
      link: "https://lihitlab-jp.ru/images/A3204/A-3204.jpg",
      cardsRoute: "/catalog/sfg/a3204",
      bestseller: false,
      new: true,
    },

    {
      id: 131,
      code: "A-3205",
      name: "Сумочка-месенджер с ремешкома",
      link: "https://lihitlab-jp.ru/images/A3205/A-3205.jpg",
      cardsRoute: "/catalog/sfg/a3205",
      bestseller: false,
      new: true,
    },

    {
      id: 131,
      code: "A-3206",
      name: "Сумочка-месенджер с ручкой",
      link: "https://lihitlab-jp.ru/images/A3206/A-3206.jpg",
      cardsRoute: "/catalog/sfg/a3206",
      bestseller: false,
      new: true,
    },

    {
      id: 131,
      code: "A-3207",
      name: "Сумка-органайзер",
      link: "https://lihitlab-jp.ru/images/A3207/A-3207.jpg",
      cardsRoute: "/catalog/sfg/a3207",
      bestseller: false,
      new: true,
    },

    {
      id: 131,
      code: "A-3208",
      name: "Сумка-органайзер L",
      link: "https://lihitlab-jp.ru/images/A3208/A-3208.jpg",
      cardsRoute: "/catalog/sfg/a3208",
      bestseller: false,
      new: true,
    },

    {
      id: 131,
      code: "A-3209",
      name: "Рюкзак",
      link: "https://lihitlab-jp.ru/images/A3209/A-3209.jpg",
      cardsRoute: "/catalog/sfg/a3209",
      bestseller: false,
      new: true,
    },

    {
      id: 131,
      code: "A-3220",
      name: "Контейнер складной, разборный 20л",
      link: "https://lihitlab-jp.ru/images/A3220/A-3220.jpg",
      cardsRoute: "/catalog/sfg/a3220",
      bestseller: false,
      new: true,
    },

    {
      id: 131,
      code: "A-3221",
      name: "Столешница для контейнера 20л",
      link: "https://lihitlab-jp.ru/images/A3221/A-3221.jpg",
      cardsRoute: "/catalog/sfg/a3221",
      bestseller: false,
      new: true,
    },

    {
      id: 131,
      code: "A-3222",
      name: "Контейнер складной, разборный 32л",
      link: "https://lihitlab-jp.ru/images/A3222/A-3222.jpg",
      cardsRoute: "/catalog/sfg/a3222",
      bestseller: false,
      new: true,
    },

    {
      id: 131,
      code: "A-3223",
      name: "Столешница для контейнера 32л",
      link: "https://lihitlab-jp.ru/images/A3223/A-3223.jpg",
      cardsRoute: "/catalog/sfg/a3223",
      bestseller: false,
      new: true,
    },






     // -----------------------------NOTEBOOK------------------------

     {
      id: 80,
      code: "N-2993",
      name: "Блокнот на пружине с обложкой из искусстенной кожи формата А5 со сменными листами (50 листов)",
      link: "https://lihitlab-jp.ru/images/N2993/N-2993.jpg",
      cardsRoute: "/catalog/note-book/n2993",
      bestseller: false,
      new: false,
    },
  {
    id: 80,
    code: "N-2658",
    name: "Блокнот перекидной на пружине формата А5 со сменными листами (30 листов)",
    link: "https://lihitlab-jp.ru/images/N2658/N-2658.jpg",
    cardsRoute: "/catalog/cube-fiz/n2658",
    bestseller: false,
    new: false,
  },
  {
    id: 81,
    code: "N-2641",
    name: "Блокнот перекидной на пружине формата А5 со сменными листами (70 листов)",
    link: "https://lihitlab-jp.ru/images/N2641/N-2641.jpg",
    cardsRoute: "/catalog/cube-fiz/n2641",
    bestseller: false,
    new: false,
  },
  {
    id: 82,
    code: "N-2608",
    name: "Блокнот перекидной на пружине формата B5 со сменными листами (30 листов)",
    link: "https://lihitlab-jp.ru/images/N2608/N-2608.jpg",
    cardsRoute: "/catalog/cube-fiz/n2608",
    bestseller: false,
    new: false,
  },
  {
    id: 83,
    code: "N-2611",
    name: "Блокнот перекидной на пружине формата B5 со сменными листами (70 листов)",
    link: "https://lihitlab-jp.ru/images/N2611/N-2611.jpg",
    cardsRoute: "/catalog/cube-fiz/n2611",
    bestseller: false,
    new: false,
  },
  {
      id: 84,
      code: "N-1670",
      name: "Блокнот перекидной на пружине формата B7 со сменными листами (50 листов)",
      link: "https://lihitlab-jp.ru/images/N1670/N-1670.jpg",
      cardsRoute: "/catalog/hirakuno/n1670",
      bestseller: false,
      new: false,
    },
    {
      id: 84,
      code: "N-1671",
      name: "Блокнот перекидной на пружине формата A6 со сменными листами (50 листов)",
      link: "https://lihitlab-jp.ru/images/N1671/N-1671.jpg",
      cardsRoute: "/catalog/hirakuno/n1671",
      bestseller: false,
      new: false,
    },
    {
      id: 85,
      code: "N-1672",
      name: "Блокнот перекидной на пружине формата B6 со сменными листами (50 листов)",
      link: "https://lihitlab-jp.ru/images/N1672/N-1672.jpg",
      cardsRoute: "/catalog/hirakuno/n1672",
      bestseller: false,
      new: false,
    },
    {
      id: 86,
      code: "N-1673",
      name: "Блокнот перекидной на пружине формата A5 со сменными листами (50 листов)",
      link: "https://lihitlab-jp.ru/images/N1673/N-1673.jpg",
      cardsRoute: "/catalog/hirakuno/n1673",
      bestseller: false,
      new: false,
    },
    {
      id: 87,
      code: "N-1674",
      name: "Блокнот перекидной на пружине формата B5 со сменными листами (50 листов)",
      link: "https://lihitlab-jp.ru/images/N1674/N-1674.jpg",
      cardsRoute: "/catalog/hirakuno/n1674",
      bestseller: false,
      new: false,
    },
    {
      id: 88,
      code: "N-1677",
      name: "Блокнот перекидной на пружине формата А6/А5Е со сменными листами (10 листов)",
      link: "https://lihitlab-jp.ru/images/N1677/N-1677.jpg",
      cardsRoute: "/catalog/hirakuno/n1677",
      bestseller: false,
      new: false,
    },
    {
      id: 89,
      code: "N-1678",
      name: "Блокнот перекидной на пружине формата B6/B5Е со сменными листами (10 листов)",
      link: "https://lihitlab-jp.ru/images/N1678/N-1678.jpg",
      cardsRoute: "/catalog/hirakuno/n1678",
      bestseller: false,
      new: false,
    },
    {
      id: 90,
      code: "N-3100",
      name: "Блокнот перекидной на пружине формата B7",
      link: "https://lihitlab-jp.ru/images/N3100/N-3100.jpg",
      cardsRoute: "/catalog/soffice/n3100",
      bestseller: false,
      new: false,
    },
    {
      id: 91,
      code: "N-3101",
      name: "Блокнот перекидной на пружине формата A6",
      link: "https://lihitlab-jp.ru/images/N3101/N-3101.jpg",
      cardsRoute: "/catalog/soffice/n3101",
      bestseller: false,
      new: false,
    },
    {
      id: 92,
      code: "N-3103",
      name: "Блокнот перекидной на пружине формата A5",
      link: "https://lihitlab-jp.ru/images/N3103/N-3103.jpg",
      cardsRoute: "/catalog/soffice/n3103",
      bestseller: false,
      new: false,
    },
    {
      id: 93,
      code: "N-3104",
      name: "Блокнот перекидной на пружине формата B5",
      link: "https://lihitlab-jp.ru/images/N3104/N-3104.jpg",
      cardsRoute: "/catalog/soffice/n3104",
      bestseller: false,
      new: false,
    },
    {
      id: 94,
      code: "N-1961",
      name: "Блокнот перекидной на пружине формата B7 со сменными листами (40 листов)",
      link: "https://lihitlab-jp.ru/images/N1961/N-1961.jpg",
      cardsRoute: "/catalog/pastello/n1961",
      bestseller: false,
      new: false,
    },
    {
      id: 95,
      code: "N-1908",
      name: "Блокнот перекидной на пружине формата B5 со сменными листами (30 листов)",
      link: "https://lihitlab-jp.ru/images/N1908/N-1908.jpg",
      cardsRoute: "/catalog/pastello/n1908",
      bestseller: false,
      new: false,
    },
    {
      id: 96,
      code: "N-1969",
      name: "Блокнот перекидной на пружине формата B6 со сменными листами (30 листов)",
      link: "https://lihitlab-jp.ru/images/N1969/N-1969.jpg",
      cardsRoute: "/catalog/pastello/n1969",
      bestseller: false,
      new: false,
    },
    {
      id: 97,
      code: "N-1958",
      name: "Блокнот перекидной на пружине формата A5 со сменными листами (30 листов)",
      link: "https://lihitlab-jp.ru/images/N1958/N-1958.jpg",
      cardsRoute: "/catalog/pastello/n1958",
      bestseller: false,
      new: false,
    },
    {
      id: 98,
      code: "N-1964",
      name: "Блокнот перекидной на пружине формата A6 со сменными листами (30 листов)",
      link: "https://lihitlab-jp.ru/images/N1964/N-1964.jpg",
      cardsRoute: "/catalog/pastello/n1964",
      bestseller: false,
      new: false,
    },


  





    
  ]
    
    