export const A2220 =
    [
        {
            id: 1,
            code: "A-2220-7",
            name: "Кошелек",
            link: "https://lihitlab-jp.ru/images/A2220/A-2220_7.jpg",
            color: "Фисташковый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222262612/detail.aspx",
        },
        {
            id: 2,
            code: "A-2220-11",
            name: "Кошелек",
            link: "https://lihitlab-jp.ru/images/A2220/A-2220_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222262613/detail.aspx",
        },
        {
            id: 3,
            code: "A-2220-16",
            name: "Кошелек",
            link: "https://lihitlab-jp.ru/images/A2220/A-2220_16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222262614/detail.aspx",
        },
        {
            id: 4,
            code: "A-2220-24",
            name: "Кошелек",
            link: "https://lihitlab-jp.ru/images/A2220/A-2220_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222262615/detail.aspx",
        },    
        
       
    ]

export const A2220POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A2220/A-2220_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A2220/A-2220_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A2220/A-2220_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A2220/A-2220_poi4.jpg",
        },
        {
            id: 5,
            link: "https://lihitlab-jp.ru/images/A2220/A-2220_poi5.jpg",
        },
      

    ]