import './Contacts.css';



function Contacts() {
  

  return (
    <section className='contacts-container'>

        <h2>Индивидуальный предприниматель Крапивко Дмитрий Витальевич;</h2>
        <p>Юридический адрес: 690048, Приморский край, г.Владивосток, ул.Вострецова, д.6В, кв.54.;</p>
        <p>ИНН: 253697874533;</p>
        <p>ОГРНИП: 321253600081149;</p>
        <p>Расчетный счет: 40802810550000053344;</p>
        <p>БИК: 040813608;</p>







    </section>
  );
}

export default Contacts;