export const A3220 =
    [
        {
            id: 1,
            code: "A-3220-0",
            name: "Контейнер складной, разборный 20л",
            link: "https://lihitlab-jp.ru/images/A3220/A-3220_0.jpg",
            color: "Белый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/225415573/detail.aspx",
        },

        {
            id: 1,
            code: "A-3220-24",
            name: "Контейнер складной, разборный 20л",
            link: "https://lihitlab-jp.ru/images/A3220/A-3220_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/225415574/detail.aspx",
        },
        
        
       
    ]

export const A3220POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3220/A-3220_poi1.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3220/A-3220_poi2.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3220/A-3220_poi3.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3220/A-3220_poi4.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3220/A-3220_poi5.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3220/A-3220_poi6.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3220/A-3220_poi7.jpg",
        },

        
      

    ]