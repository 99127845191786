export const A3206 =
    [
        {
            id: 1,
            code: "A-3206-11",
            name: "Сумочка-месенджер с ручкой",
            link: "https://lihitlab-jp.ru/images/A3206/A-3206_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/225425666/detail.aspx",
        },

        {
            id: 1,
            code: "A-3206-14",
            name: "Сумочка-месенджер с ручкой",
            link: "https://lihitlab-jp.ru/images/A3206/A-3206_14.jpg",
            color: "Голубой",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/225425667/detail.aspx",
        },

        {
            id: 1,
            code: "A-3206-16",
            name: "Сумочка-месенджер с ручкой",
            link: "https://lihitlab-jp.ru/images/A3206/A-3206_16.jpg",
            color: "Серый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/225425668/detail.aspx",
        },

        {
            id: 1,
            code: "A-3206-22",
            name: "Сумочка-месенджер с ручкой",
            link: "https://lihitlab-jp.ru/images/A3206/A-3206_22.jpg",
            color: "Зеленый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/225425669/detail.aspx",
        },

        {
            id: 1,
            code: "A-3206-24",
            name: "Сумочка-месенджер с ручкой",
            link: "https://lihitlab-jp.ru/images/A3206/A-3206_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/225425670/detail.aspx",
        },
        
        
       
    ]

export const A3206POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3206/A-3206_poi1.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3206/A-3206_poi2.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3206/A-3206_poi3.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3206/A-3206_poi4.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3206/A-3206_poi5.jpg",
        },
          
      

    ]