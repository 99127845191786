import './Slider.css';

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function SimpleSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    
  };

  return (
    <div className='slider-container'>
      <Slider {...settings}>
        <div>
          <img
            className='img'
            src='https://lihitlab-jp.ru/images/img-puni01.jpg'
            alt='slide-1'
          />
        </div>
        <div>
          <img
            className='img'
            src='https://lihitlab-jp.ru/images/altna3_series_top.jpg'
            alt='slide-1'
          />
        </div>
        <div>
          <img
            className='img'
            src='https://lihitlab-jp.ru/images/smft_img01.jpg'
            alt='slide-1'
          />
        </div>
        <div>
          <img
            className='img'
            src='https://lihitlab-jp.ru/images/img-acta01.jpg'
            alt='slide-1'
          />
        </div>
        <div>
          <img
            className='img'
            src='https://lihitlab-jp.ru/images/hinemo_ban1.jpg'
            alt='slide-1'
          />
        </div>
        <div>
          <img
            className='img'
            src='https://lihitlab-jp.ru/images/bnr_cat_14-1.jpg'
            alt='slide-1'
          />
        </div>
      </Slider>
    </div>
  );
}

export default SimpleSlider;
