import "./Main.css";

import headerBrend from "../../images/LL.png";

import wb from "../../images/wb.png";

import React from "react";

import { useState, useEffect } from "react";

import Baner from "../Baner/Baner";

import New from "../New/New";

import Productline from "../Productline/Productline";

import { NEW } from "../../utils/new/new";

import { HIT } from "../../utils/new/hit";

function Main() {
    const [newcard, setNewcard] = useState(NEW);

    const [hitcard, setHitcard] = useState(HIT);

    return (
        <main className="content">
            <h2 className="new-title">О нас</h2>

            <p className="paragraf">
                Мы являемся официальным
                представителем японской компании{" "}
                <a target="blank" href="https://www.lihit-lab.com/">
                    <img
                        className="paragraf__img"
                        src={headerBrend}
                        alt="Бренд LIHIT LAB"
                    />
                </a>{" "}
                специализирующейся на производстве и продаже канцелярских
                товаров и аксессуаров с 1938г. Продукция компании представленна
                во всем мире.
            </p>

            <p className="paragraf">
                Вся продукция является оригинальным товаром японского бренда{" "}
                <a target="blank" href="https://www.lihit-lab.com/">
                    <img
                        className="paragraf__img"
                        src={headerBrend}
                        alt="Бренд LIHIT LAB"
                    />
                </a>{" "}
                и поставляется напрямую из Японии. Обладает высоким качеством
                признанной во всем мире и прослужит вам долгое время. Вся
                продукция подтверждена сертификатами качества и декларациями
                соответствия.
            </p>

            <p className="paragraf">
                Весь наш ассортимент представлен на маркетплейсе{" "}
                <a
                    target="blank"
                    href="https://www.wildberries.ru/brands/lihit-lab"
                >
                    <img
                        className="paragraf__img"
                        src={wb}
                        alt="Бренд LIHIT LAB"
                    />
                </a>{" "}
                , где можно ознакомится с нашей продукцией и приобрести
                понравившиеся Вам товары.
            </p>

            <p className="paragraf">
                ИП Крапивко Дмитрий Витальевич. 690048, Приморский край,
                г.Владивосток, ул.Вострецова, д.6В, кв.54 ИНН 253697874533
                ОГРНИП: 321253600081149.
            </p>

            <a href="https://lihitlab-jp.ru/brend" className="main__link-brend">
                Узнать больше о бренде LIHIT LAB.
            </a>

            <New newsItem={newcard} title={"Новинки"} />

            <New newsItem={hitcard} title={"Хиты продаж"} />

            <Productline />
        </main>
    );
}

export default Main;
