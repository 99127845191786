export const SFG = [
  
  {
    id: 130,
    code: "A-3200",
    name: "Пенал-органайзер",
    link: "https://lihitlab-jp.ru/images/A3200/A-3200.jpg",
    cardsRoute: "/catalog/sfg/a3200",
    bestseller: false,
    new: true,
  },

  {
    id: 131,
    code: "A-3201",
    name: "Пенал органайзер L-образный",
    link: "https://lihitlab-jp.ru/images/A3201/A-3201.jpg",
    cardsRoute: "/catalog/sfg/a3201",
    bestseller: false,
    new: true,
  },

  {
    id: 131,
    code: "A-3202",
    name: "Папка-сумка L-образная",
    link: "https://lihitlab-jp.ru/images/A3202/A-3202.jpg",
    cardsRoute: "/catalog/sfg/a3202",
    bestseller: false,
    new: true,
  },

  {
    id: 131,
    code: "A-3203",
    name: "Кошелек-ключница на карабине",
    link: "https://lihitlab-jp.ru/images/A3203/A-3203.jpg",
    cardsRoute: "/catalog/sfg/a3203",
    bestseller: false,
    new: true,
  },

  {
    id: 131,
    code: "A-3204",
    name: "Сумочка поясная для телефона",
    link: "https://lihitlab-jp.ru/images/A3204/A-3204.jpg",
    cardsRoute: "/catalog/sfg/a3204",
    bestseller: false,
    new: true,
  },

  {
    id: 131,
    code: "A-3205",
    name: "Сумочка-месенджер с ремешкома",
    link: "https://lihitlab-jp.ru/images/A3205/A-3205.jpg",
    cardsRoute: "/catalog/sfg/a3205",
    bestseller: false,
    new: true,
  },

  {
    id: 131,
    code: "A-3206",
    name: "Сумочка-месенджер с ручкой",
    link: "https://lihitlab-jp.ru/images/A3206/A-3206.jpg",
    cardsRoute: "/catalog/sfg/a3206",
    bestseller: false,
    new: true,
  },

  {
    id: 131,
    code: "A-3207",
    name: "Сумка-органайзер",
    link: "https://lihitlab-jp.ru/images/A3207/A-3207.jpg",
    cardsRoute: "/catalog/sfg/a3207",
    bestseller: false,
    new: true,
  },

  {
    id: 131,
    code: "A-3208",
    name: "Сумка-органайзер L",
    link: "https://lihitlab-jp.ru/images/A3208/A-3208.jpg",
    cardsRoute: "/catalog/sfg/a3208",
    bestseller: false,
    new: true,
  },

  {
    id: 131,
    code: "A-3209",
    name: "Рюкзак",
    link: "https://lihitlab-jp.ru/images/A3209/A-3209.jpg",
    cardsRoute: "/catalog/sfg/a3209",
    bestseller: false,
    new: true,
  },

  {
    id: 131,
    code: "A-3220",
    name: "Контейнер складной, разборный 20л",
    link: "https://lihitlab-jp.ru/images/A3220/A-3220.jpg",
    cardsRoute: "/catalog/sfg/a3220",
    bestseller: false,
    new: true,
  },

  {
    id: 131,
    code: "A-3221",
    name: "Столешница для контейнера 20л",
    link: "https://lihitlab-jp.ru/images/A3221/A-3221.jpg",
    cardsRoute: "/catalog/sfg/a3221",
    bestseller: false,
    new: true,
  },

  {
    id: 131,
    code: "A-3222",
    name: "Контейнер складной, разборный 32л",
    link: "https://lihitlab-jp.ru/images/A3222/A-3222.jpg",
    cardsRoute: "/catalog/sfg/a3222",
    bestseller: false,
    new: true,
  },

  {
    id: 131,
    code: "A-3223",
    name: "Столешница для контейнера 32л",
    link: "https://lihitlab-jp.ru/images/A3223/A-3223.jpg",
    cardsRoute: "/catalog/sfg/a3223",
    bestseller: false,
    new: true,
  },
     
      
    ]