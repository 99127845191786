export const A2225 =
    [
        {
            id: 1,
            code: "A-2225-7",
            name: "Сумочка кошелек с ремешком",
            link: "https://lihitlab-jp.ru/images/A2225/A-2225_7.jpg",
            color: "Фисташковый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222416825/detail.aspx",
        },
        {
            id: 2,
            code: "A-2225-11",
            name: "Сумочка кошелек с ремешком",
            link: "https://lihitlab-jp.ru/images/A2225/A-2225_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222416826/detail.aspx",
        },
        {
            id: 3,
            code: "A-2225-16",
            name: "Сумочка кошелек с ремешком",
            link: "https://lihitlab-jp.ru/images/A2225/A-2225_16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222416827/detail.aspx",
        },
        {
            id: 4,
            code: "A-2225-24",
            name: "Сумочка кошелек с ремешком",
            link: "https://lihitlab-jp.ru/images/A2225/A-2225_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222416828/detail.aspx",
        },    
        
       
    ]

export const A2225POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A2225/A-2225_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A2225/A-2225_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A2225/A-2225_poi3.jpg",
        },
        {
            id: 4,
            link: "https://lihitlab-jp.ru/images/A2225/A-2225_poi4.jpg",
        },
           
      
      

    ]