export const A3205 =
    [
        {
            id: 1,
            code: "A-3205-11",
            name: "Кошелек-ключница на карабине",
            link: "https://lihitlab-jp.ru/images/A3205/A-3205_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/225401556/detail.aspx",
        },

        {
            id: 1,
            code: "A-3205-14",
            name: "Кошелек-ключница на карабине",
            link: "https://lihitlab-jp.ru/images/A3205/A-3205_14.jpg",
            color: "Голубой",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/225401557/detail.aspx",
        },

        {
            id: 1,
            code: "A-3205-16",
            name: "Кошелек-ключница на карабине",
            link: "https://lihitlab-jp.ru/images/A3205/A-3205_16.jpg",
            color: "Серый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/225401558/detail.aspx",
        },

        {
            id: 1,
            code: "A-3205-22",
            name: "Кошелек-ключница на карабине",
            link: "https://lihitlab-jp.ru/images/A3205/A-3205_22.jpg",
            color: "Зеленый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/225401559/detail.aspx",
        },

        {
            id: 1,
            code: "A-3205-24",
            name: "Кошелек-ключница на карабине",
            link: "https://lihitlab-jp.ru/images/A3205/A-3205_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/225401560/detail.aspx",
        },
        
        
       
    ]

export const A3205POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3205/A-3205_poi1.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3205/A-3205_poi2.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3205/A-3205_poi3.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3205/A-3205_poi4.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3205/A-3205_poi5.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3205/A-3205_poi6.jpg",
        },

        
      

    ]