export const A3200 =
    [
        {
            id: 1,
            code: "A-3200-11",
            name: "Пенал-органайзер",
            link: "https://lihitlab-jp.ru/images/A3200/A-3200_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222511102/detail.aspx",
        },

        {
            id: 1,
            code: "A-3200-14",
            name: "Пенал-органайзер",
            link: "https://lihitlab-jp.ru/images/A3200/A-3200_14.jpg",
            color: "Голубой",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222511103/detail.aspx",
        },

        {
            id: 1,
            code: "A-3200-16",
            name: "Пенал-органайзер",
            link: "https://lihitlab-jp.ru/images/A3200/A-3200_16.jpg",
            color: "Серый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222511104/detail.aspx",
        },

        {
            id: 1,
            code: "A-3200-22",
            name: "Пенал-органайзер",
            link: "https://lihitlab-jp.ru/images/A3200/A-3200_22.jpg",
            color: "Зеленый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222511105/detail.aspx",
        },

        {
            id: 1,
            code: "A-3200-24",
            name: "Пенал-органайзер",
            link: "https://lihitlab-jp.ru/images/A3200/A-3200_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222511106/detail.aspx",
        },
        
        
       
    ]

export const A3200POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3200/A-3200_poi1.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3200/A-3200_poi2.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3200/A-3200_poi3.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3200/A-3200_poi4.jpg",
        },
        
      

    ]