export const A3207 =
    [
        {
            id: 1,
            code: "A-3207-11",
            name: "Сумка-органайзер",
            link: "https://lihitlab-jp.ru/images/A3207/A-3207_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/225408382/detail.aspx",
        },

        {
            id: 1,
            code: "A-3207-16",
            name: "Сумка-органайзер",
            link: "https://lihitlab-jp.ru/images/A3207/A-3207_16.jpg",
            color: "Серый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/225408383/detail.aspx",
        },

        {
            id: 1,
            code: "A-3207-24",
            name: "Сумка-органайзер",
            link: "https://lihitlab-jp.ru/images/A3207/A-3207_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/225408384/detail.aspx",
        },
        
        
       
    ]

export const A3207POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3207/A-3207_poi1.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3207/A-3207_poi2.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3207/A-3207_poi3.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3207/A-3207_poi4.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3207/A-3207_poi5.jpg",
        },

        
      

    ]