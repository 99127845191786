export const A2226 =
    [
        {
            id: 1,
            code: "A-2226-7",
            name: "Сумка-органайзер для ноутбука",
            link: "https://lihitlab-jp.ru/images/A2226/A-2226_7.jpg",
            color: "Фисташковый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222424736/detail.aspx",
        },
        {
            id: 2,
            code: "A-2226-11",
            name: "Сумка-органайзер для ноутбука",
            link: "https://lihitlab-jp.ru/images/A2226/A-2226_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222424737/detail.aspx",
        },
        {
            id: 3,
            code: "A-2226-16",
            name: "Сумка-органайзер для ноутбука",
            link: "https://lihitlab-jp.ru/images/A2226/A-2226_16.jpg",
            color: "Бежевый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222424738/detail.aspx",
        },
        {
            id: 4,
            code: "A-2226-24",
            name: "Сумка-органайзер для ноутбука",
            link: "https://lihitlab-jp.ru/images/A2226/A-2226_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222424739/detail.aspx",
        },    
        
       
    ]

export const A2226POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A2226/A-2226_poi1.jpg",
        },
        {
            id: 2,
            link: "https://lihitlab-jp.ru/images/A2226/A-2226_poi2.jpg",
        },
        {
            id: 3,
            link: "https://lihitlab-jp.ru/images/A2226/A-2226_poi3.jpg",
        },
        
      
      

    ]