export const A3201 =
    [
        {
            id: 1,
            code: "A-3201-11",
            name: "Пенал органайзер L-образный",
            link: "https://lihitlab-jp.ru/images/A3201/A-3201_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222513328/detail.aspx",
        },

        {
            id: 1,
            code: "A-3201-14",
            name: "Пенал органайзер L-образный",
            link: "https://lihitlab-jp.ru/images/A3201/A-3201_14.jpg",
            color: "Голубой",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222513329/detail.aspx",
        },

        {
            id: 1,
            code: "A-3201-16",
            name: "Пенал органайзер L-образный",
            link: "https://lihitlab-jp.ru/images/A3201/A-3201_16.jpg",
            color: "Серый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222513330/detail.aspx",
        },

        {
            id: 1,
            code: "A-3201-22",
            name: "Пенал органайзер L-образный",
            link: "https://lihitlab-jp.ru/images/A3201/A-3201_22.jpg",
            color: "Зеленый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222513331/detail.aspx",
        },

        {
            id: 1,
            code: "A-3201-24",
            name: "Пенал органайзер L-образный",
            link: "https://lihitlab-jp.ru/images/A3201/A-3201_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/222513332/detail.aspx",
        },
        
        
       
    ]

export const A3201POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3201/A-3201_poi1.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3201/A-3201_poi2.jpg",
        },

        
      

    ]