export const A3204 =
    [
        {
            id: 1,
            code: "A-3204-11",
            name: "Папка-сумка L-образная",
            link: "https://lihitlab-jp.ru/images/A3204/A-3204_11.jpg",
            color: "Темно-синий",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/224490162/detail.aspx",
        },

        {
            id: 1,
            code: "A-3204-16",
            name: "Папка-сумка L-образная",
            link: "https://lihitlab-jp.ru/images/A3204/A-3204_16.jpg",
            color: "Серый",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/224490164/detail.aspx",
        },

        {
            id: 1,
            code: "A-3204-24",
            name: "Папка-сумка L-образная",
            link: "https://lihitlab-jp.ru/images/A3204/A-3204_24.jpg",
            color: "Черный",
            bestseller: false,
            new: true,
            linkWB:"https://www.wildberries.ru/catalog/224490165/detail.aspx",
        },
        
        
       
    ]

export const A3204POI =
    [
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3204/A-3204_poi1.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3204/A-3204_poi2.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3204/A-3204_poi3.jpg",
        },
        {
            id: 1,
            link: "https://lihitlab-jp.ru/images/A3204/A-3204_poi4.jpg",
        },

        
      

    ]