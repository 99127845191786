import './Header.css';




import headerLogo from "../../images/logo.jpg";
import headerBrend from "../../images/LL.png";



function Header() {
  return (
  <header className="header">
    <div className="header__wrap">

    <img className="header__logo" src={headerLogo} alt="Логотип LIHIT LAB"/>
    <div className="header__wrap-title">
      <img className="header__title" src={headerBrend} alt="Бренд LIHIT LAB"/>
      <p className="header__subtitle">Японские товары и аксессуары</p>
    </div> 

    </div>
    

    <div className="search-form">

                <form className="search-form__movies">

                    <input className="search-form__movies-name" type="text" placeholder="Найти"></input>


                    <button className="search-form__find" type="button" >Найти</button>

                </form>

            </div>






    <div className="header__tel-wrap">


      
    <a href="tel:+79147941128" className="header__tel">8-914-794-11-28</a>
       <p className="header__tel-text">Звоните с 9.00 до 22.00 по Владивостоку</p>



    </div>




  </header>
  );
}

export default Header;
