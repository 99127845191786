export const NYANSCENE = [
    {
        id: 24,
        code: "A-2220",
        name: "Кошелек",
        link: "https://lihitlab-jp.ru/images/A2220/A-2220.jpg",
        cardsRoute: "/catalog/nyan-scene/a2220",
        bestseller: false,
        new: true,
      },
      {
        id: 25,
        code: "A-2221",
        name: "Пенал-плоский",
        link: "https://lihitlab-jp.ru/images/A2221/A-2221.jpg",
        cardsRoute: "/catalog/nyan-scene/a2221",
        bestseller: false,
        new: true,
      },
      {
        id: 26,
        code: "A-2222",
        name: "Клатч плоский",
        link: "https://lihitlab-jp.ru/images/A2222/A-2222.jpg",
        cardsRoute: "/catalog/nyan-scene/a2222",
        bestseller: false,
        new: true,
      },

      {
        id: 27,
        code: "A-2223",
        name: "Пенал широкий",
        link: "https://lihitlab-jp.ru/images/A2223/A-2223.jpg",
        cardsRoute: "/catalog/nyan-scene/a2223",
        bestseller: false,
        new: true,
      },

      {
        id: 28,
        code: "A-2224",
        name: "Пенал-косметичка",
        link: "https://lihitlab-jp.ru/images/A2224/A-2224.jpg",
        cardsRoute: "/catalog/nyan-scene/a2224",
        bestseller: false,
        new: true,
      },

      {
        id: 29,
        code: "A-2225",
        name: "Сумочка кошелек с ремешком",
        link: "https://lihitlab-jp.ru/images/A2225/A-2225.jpg",
        cardsRoute: "/catalog/nyan-scene/a2225",
        bestseller: false,
        new: true,
      },

      {
        id: 30,
        code: "A-2226",
        name: "Сумка-органайзер для ноутбука",
        link: "https://lihitlab-jp.ru/images/A2226/A-2226.jpg",
        cardsRoute: "/catalog/nyan-scene/a2226",
        bestseller: false,
        new: true,
      },
     
      
    ]